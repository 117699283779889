import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';

const AdminLayoutForm = ({ tagList, setTagList }) => {
    const handleOnDragEnd = (e) => {
        if (!e.destination) return

        const newTagList = Array.from(tagList)
        const [reorderedTag] = newTagList.splice(e.source.index, 1);
        newTagList.splice(e.destination.index, 0, reorderedTag)

        setTagList(newTagList);
    }

    const deleteTag = (tagId) => {
        const newTagList = Array.from(tagList);
        const removeTagList = newTagList.filter(current => {
            return current.id !== tagId;
        })
        setTagList(removeTagList);
    }

    return (
        <>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId='tag-list'>
                {(provided) => (
                <ul 
                    {...provided.droppableProps} ref={provided.innerRef}
                    className='layout-container'
                >
                    {tagList.map((item, index) => {
                        return (
                            <Draggable key={item.id} draggableId={('' + item.id)} index={index}>
                                {(provided) => (
                                <li 
                                    {...provided.draggableProps} {...provided.dragHandleProps}
                                    ref={provided.innerRef} className='single-layout' data-testid='layout-element'
                                >
                                    <div className='single-layout-inner'>
                                        <MenuIcon />
                                        <p>{item.name}</p>
                                    </div>
                                    <CancelIcon onClick={() => deleteTag((item.id))} style={{
                                        color: 'red',
                                        borderRadius: '5px',
                                        fontSize: 'medium',
                                        cursor: 'pointer'
                                    }}/>
                                </li>
                                )}
                            </Draggable>
                        )
                    })}
                    {provided.placeholder}
                </ul>
                )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

export default AdminLayoutForm;