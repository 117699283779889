import React from 'react';
import '../../css/admin-css/AdminNavbars.css'

import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';

const AdminNavbarButton = ({ sidebarStatus, showSidebar }) => {
    return (
        <>
            <div className='sidebar-top'>
                <span onClick={showSidebar}>{sidebarStatus ? <FormatIndentDecreaseIcon fontSize='large'/> : <FormatIndentIncreaseIcon fontSize='large'/> }</span>
            </div>
        </>
    );
}

export default AdminNavbarButton;

// &#9776;&#88;