import '../css/Button.css';

import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import React from 'react';
import ShareIcon from '@mui/icons-material/Share';

export const LikeButton = ({onClick, isLiked, disabled, text='Like'}) => {
    let icon = <FavoriteBorder htmlColor='inherit'/>;

    if (isLiked) {
        text = 'Liked';
        icon = <FavoriteIcon htmlColor='inherit' />
    }

    return (
        <Button
            text={text}
            onClick={onClick}
            disabled={disabled}
            test_id='like-button'
            type='no-style'
            className={isLiked ? 'btn__liked' : ''}
            icon={icon}
        />
    )
}

export const ShareButton = ({onClick, className}) => {
    const icon = <ShareIcon />;

    return (
        <Button
            className={className ? className : ''}
            text={'Share'}
            test_id="share-button"
            icon={icon}
            onClick={onClick}
        />
    )

}

export const LinkButton = ({ url, text, size, icon, onClick }) => {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer" className="btn-link">
            <Button
                text={text}
                icon={icon}
                size={size}
                onClick={onClick}
            />
        </a>
    )
}

export const TextButton = ({ children, onClick, disabled, className }) => {

    return (
        <button
            className={`btn btn__no-style ${className}`}
            onClick={onClick}
            disabled={disabled}
            type="button"
        >
            {children}
        </button>
    )
}

const Button = ({ text , onClick, disabled, test_id, type, icon, className, isSubmit, customText }) => {

    // Type can either be primary, secondary, or no-style
    const buttonType = `btn__${type ? type : 'no-style'}`;

    return (
        <button
            className={`btn ${className || ''} ${buttonType}`}
            onClick={onClick}
            disabled={disabled}
            data-testid={test_id}
            type={isSubmit ? 'submit' : 'button'}
        >
            {icon}
            {text && <p className="btn__text">{text}</p>}
            {customText && customText}
        </button>
    )

}

export default Button;
