import axios from 'axios';

// API Endpoint helper functions. Currently, these all return static data
// but should be updated with actual axios calls when backend API endpoints are implemented

// Admin Accounts
const createNewAdmin = async (newAdmin, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.post('/api/admin/users', newAdmin, { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const getAdminInfo = async (id, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }
    const apiLink = "/api/users/" + id;
    const result = await axios.get(apiLink, { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
} 

const updatePassword = async (psswInfo, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.put('/api/users/password', psswInfo, { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const updateAdmin = async (newInfo, user) => {
    const body = {
        id: user.id.toString(),
        email: newInfo.email,
        firstName: newInfo.fName,
        lastName: newInfo.lName
    }

    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const update = await axios.put('/api/admin/users', body, { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return update;
}

const loginAdmin = async (credentials) => {
    const result = await axios.post('/api/login', credentials)
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

// Dashboard
const retrieveDashboardData = async (user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const dashboard = await axios.get('/api/admin/dashboard', { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return dashboard;
}

// Videos
const updateFeaturedVideo = async (videoInfo, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.put('/api/admin/videos/featured', videoInfo, { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const importRecentVideos = async (user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.get('/api/admin/videos/import', { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const getVideoImport = async (youtubeId, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.post('/api/admin/videos', {
        id: youtubeId
    }, { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const retrieveVideos = async (query, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const results = await axios.get('/api/admin/search/videos/' + query, { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return results;
}

const uploadVideo = async (videoToUpload, publicStatus, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const jsonQuery = videoToUpload;
    jsonQuery.id = videoToUpload.id.toString();
    jsonQuery.public_state = publicStatus;

    if (videoToUpload.epnum === null) {
        jsonQuery.epnum = "-1";
    } else {
        jsonQuery.epnum = jsonQuery.epnum.toString();
    }

    const result = await axios.put('/api/admin/videos', jsonQuery, { headers })
    .then(response => ({
        status: response.status,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const retrieveOneVideo = async (videoId, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const query = '/api/videos/' + videoId;
    
    const result = await axios.get(query, { headers })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const deleteVideo = async (videoId, user) => {
    const result = await axios.delete('/api/admin/videos', {
        headers: {
            'Authorization' : 'Bearer ' + user.token
        },
        data: {
            id: videoId.toString()
        }
    })
    .then(response => ({
        status: response.status,
        data: response.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

// Tags
const retrieveLayout = async (user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const results = await axios.get('/api/tags/featured', { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))
    
    return results;
}

const updateLayout = async (newLayout, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    let idLayout = []
    for (var x in newLayout) {
        idLayout.push(newLayout[x].id);
    }

    let tagsToPut = {
        id: idLayout
    }

    const results = await axios.put('/api/tags/featured', tagsToPut, { headers })
    .then(response => ({
        status: response.status,
    })). catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return results;
}

const retrieveTags = async (user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const results = await axios.get('/api/tags', { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return results;
}

const addTags = async (tags, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.post('/api/tags', tags, { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const updateTags = async(tags, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.put('/api/tags', tags, { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const deleteTags = async(tags, user) => {
    const result = await axios.delete('/api/tags', {
        headers: {
            'Authorization' : 'Bearer ' + user.token
        },
        data: {
            epid: tags.epid,
            tagid: tags.tagid
        }
    })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const updateAllTags = async (user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.put('/api/tags/autotag', "", { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))
    
    return result;
}

// Users
const retrieveAllAdmins = async (user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const admins = await axios.get('/api/admin/users', { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return admins;
}

const retrieveAllUsers = async (user) => {

    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const users = await axios.get('/api/users', { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return users;
}

const deleteUser = async (user, authorization) => {
    const result = await axios.delete('/api/users', {
        headers: {
            'Authorization' : 'Bearer ' + authorization.token
        },
        data: {
            "id": user
        }
    })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

// Artists
const retrieveFeaturedArtist = async (user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const artists = await axios.get('/api/featured-artists', { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return artists;
}

const retrieveArtists = async (query, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.get('/api/search/' + query, { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const createNewArtist = async (artist, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.post('/api/admin/artists', artist, { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result
}

const getArtist = async (artist, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const query = '/api/artists/' + artist;

    const result = await axios.get(query, { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const editArtist = async (artist, user) => {
    const headers = {
        'Authorization' : 'Bearer ' + user.token
    }

    const result = await axios.put('/api/admin/artists', artist, { headers })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result
}

const deleteArtist = async (artist, user) => {
    const result = await axios.delete('/api/admin/artists', {
        headers: {
            'Authorization' : 'Bearer ' + user.token
        },
        data: {
            "id": artist
        }
    })
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result;
}

const adminApiHelpers = {
    createNewAdmin,
    getAdminInfo,
    updatePassword,
    updateAdmin,
    loginAdmin,
    retrieveDashboardData,
    retrieveLayout,
    updateLayout,
    retrieveTags,
    addTags,
    updateTags,
    deleteTags,
    updateAllTags,
    updateFeaturedVideo,
    importRecentVideos,
    getVideoImport,
    retrieveVideos,
    uploadVideo,
    retrieveOneVideo,
    deleteVideo,
    retrieveAllAdmins,
    retrieveAllUsers,
    deleteUser,
    retrieveFeaturedArtist,
    retrieveArtists,
    createNewArtist,
    getArtist,
    editArtist,
    deleteArtist,
}

export default adminApiHelpers;