import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';
import ConfirmVideoDelete from './ConfirmVideoDelete';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminListOfVideos.css'

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// TODO: have a page system for the searches

const AdminListOfVideos = ({ videoQuery, user }) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [rerender, setRerender] = useState(true);
    const [videoToDelete, setVideoToDelete] = useState({
        id: '',
        name: ''
    });
    const [videoList, setVideoList] = useState([])
    const [videoListInfo, setVideoListInfo] = useState({
        length: 0
    })

    useEffect(() => {
        const lowerCased = videoQuery.toLowerCase();
        
        async function findVideos() {
            const search = await adminApiHelpers.retrieveVideos(lowerCased, user);

            if (search.status !== STATUS_CODES.SUCCESS) {
                console.log("Error retrieving videos");
            } else {
                setVideoList(search.data.data.videos);
                setVideoListInfo({
                    length: search.data.data.pageInfo.videoResults
                })
            }
        }

        if (videoQuery !== '') {
            findVideos();
        }

    }, [videoQuery, rerender])

    const showConfirmDelete = (video) => { 
        setVideoToDelete(video);
        setConfirmDelete(!confirmDelete); 
    }

    const doDelete = () => {
        setRerender(!rerender);
    }

    return (
        <>
            <ul className='video-list-container'>
                <div className='video-list-grid'>
                    <p></p>
                    <p className='bold'>Public/Private</p>
                    <p className='bold'>Actions</p>
                </div>
                {(videoQuery === '' || videoListInfo.length <= 0) ? 
                <div>
                    <p className='empty-query'>No results based on search</p>
                </div> : 
                <div>
                    {videoList.map((item, index) => {
                        return (
                            <li key={index} className='video-find-container' data-testid='video-result'>
                                <div className='thumbnail-container'>
                                    <img src={item.thumbnail}/>
                                    <div className='thumbnail-titles'>
                                        <p className='bold'>{item.title}</p>
                                    </div>
                                </div>
                                <div>
                                    <p className={item.public_state ? "green-font" : "red-font"}>
                                        {item.public_state ? "Public" : "Private"}
                                    </p>
                                </div>
                                <div className='buttons-container'>
                                    <div>
                                        <Link to={'/admin/editVideo/' + item.id} state={{ videoID: item.id}}>
                                            <EditIcon style={{ color: 'black' }}/>
                                        </Link>
                                    </div>
                                    <div>
                                        <DeleteIcon 
                                            onClick={() => showConfirmDelete(item)} 
                                            style={{ color: 'red', cursor: 'pointer' }}
                                        />
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </div>}
            </ul>
            <ConfirmVideoDelete 
                trigger={confirmDelete} videoInfo={videoToDelete} user={user}
                showConfirmation={showConfirmDelete} doDelete={doDelete}
            />
        </>
    );
}

export default AdminListOfVideos;