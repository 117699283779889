import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../context/UserContext';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminForms.css'

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';
import AdminArtistForm from '../../components/admin-components/AdminArtistForm';

const AdminEditArtist = () => {
    const params = useParams();
    const [sidebar, setSidebar] = useState(true);
    const [idOfArtist, setIdOfArtist] = useState(null);
    const [artistInfo, setArtistInfo] = useState(null);

    const { user, logout } = useUserContext();

    useEffect(() => {
        setIdOfArtist(params.id);
    }, [])

    useEffect(() => {
        async function fetchArtist(id) {
            const getArtistInfo = await adminApiHelpers.getArtist(id, user);

            if (getArtistInfo.status !== STATUS_CODES.SUCCESS) {
                console.log("Error Loading Page");
            } else {
                setArtistInfo(getArtistInfo.data.artist);
            }
        }
        
        let idToSend = idOfArtist;
        fetchArtist(idToSend);
    }, [idOfArtist])

    // If user isn't logged in or is not an admin, redirect to admin home page
    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    const showSidebar = () => { setSidebar(!sidebar); }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Artists" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                {artistInfo === null ? <div></div> : <AdminArtistForm pageTitle={'Edit Artist'} artistToEdit={artistInfo} newArtist={false} artistId={params.id} user={user}/>}
            </div>
        </>
    );
}

export default AdminEditArtist;