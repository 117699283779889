import React, { useState } from 'react';

import Button from '../components/Button';
import ErrorMessage from './Error';
import InputField from './InputField';
import { STATUS_CODES } from '../helpers/apiHelpers';
import { UserAuthStateEnum } from './UserAuthModal';
import { useUserContext } from '../context/UserContext';
import { validateEmail } from '../helpers/fieldValidators';

export const UserLoginForm = (props) => {

    const { login } = useUserContext();

    const { handleClose, updateFormState, subheading } = props

    const [isLoading, setIsLoading] = useState(false)

    const [messages, setMessages] = useState({
        email: '',
        password: '',
        api: '',
    })

    const validateFields = (email, password) => {

        const fieldErrors = {
            email: '',
            password: '',
        }

        if (!email) {
            fieldErrors.email = 'Email is required'
        } else if (!validateEmail(email) && email !== 'user' && email !== 'admin') {
            // user and admin are for testing purposes, remove when backend is ready
            fieldErrors.email = 'Email is invalid'
        }

        if (!password) {
            fieldErrors.password = 'Password is required'
        }

        return fieldErrors;
    }

    const onSubmit = async (e) => {

        e.preventDefault();

        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;

        const fieldErrors = validateFields(email, password);
        const valid = Object.values(fieldErrors).every((field) => field === '');

        if (!valid) {
            setMessages({...fieldErrors, api: ''});
            return;
        }

        setIsLoading(true)
        const result = await login(email, password)

        if (result.status !== STATUS_CODES.POST_SUCCESS) {
            setMessages({
                ...fieldErrors,
                api: result.message
            })
            setIsLoading(false)
        } else {
            handleClose()
        }

    }

    return (
        <div>
            <Button
                type="no-style"
                onClick={handleClose}
                className="close-btn"
                text="Close"
            />
            <div className="login-form__header">
                <h1>Sign In</h1>
                <h2 className="login-form__subheading">{subheading}</h2>
                <ErrorMessage errorMessage={messages.api} />
            </div>
            <form id="login-form">
                <InputField
                    id="email"
                    type="text"
                    label="Email"
                    placeholder="Enter an email address"
                    error={messages.email}
                    disabled={isLoading}
                    compact
                />
                <InputField
                    id="password"
                    type="password"
                    label="Password"
                    placeholder="Enter a password"
                    error={messages.password}
                    disabled={isLoading}
                    compact
                />
                <div className="form-controls">
                    <Button
                        type="no-style"
                        text="Forgot password?"
                        onClick={() => updateFormState(UserAuthStateEnum.FORGOTPASSWORD)}
                        disabled={isLoading}
                    />
                    <Button
                        text="SIGN IN"
                        type="primary"
                        onClick={onSubmit}
                        disabled={isLoading}
                        isSubmit
                    />
                    <Button
                        type="no-style"
                        customText={<p>Don't have an account? <strong>Sign Up</strong></p>}
                        // text="Don't have an account? Sign Up"
                        onClick={() => updateFormState(UserAuthStateEnum.REGISTER)}
                        disabled={isLoading}
                    />
                </div>
            </form>
        </div>
    )
}

