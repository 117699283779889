import React from 'react'
import { Link } from 'react-router-dom';

import '../../css/admin-css/AdminNavbars.css'
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LogoutIcon from '@mui/icons-material/Logout';

// React component that contains the navbar of the Admin pages
const AdminNavbar = ({ adminName, sidebarStatus, currentPage, logout }) => {
    
    return (
        <>
            <div className={sidebarStatus ? 'admin-navbar active' : 'admin-navbar'}>
                <h2>{adminName}</h2>
                <Link to='/admin/ownAccount' className='view-account-link'>
                    View Account
                </Link>
                <ul className='admin-navbar-menu'>
                    <li>
                        <Link to='/admin/dashboard'>
                            <div className={(currentPage === 'Dashboard') ? 'admin-navbar-menu-list-item current' : 'admin-navbar-menu-list-item'}>
                                <DashboardIcon />  
                                <span>Dashboard</span>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        {/* Change path to Analytics
                        <Link to='/admin/dashboard'>
                            <div className={(currentPage === 'Analytics') ? 'admin-navbar-menu-list-item current' : 'admin-navbar-menu-list-item'}>
                                <AnalyticsIcon />  
                                <span>Analytics</span>
                            </div>
                        </Link>
                    </li> */}
                    <li>
                        <Link to='/admin/videos'>
                            <div className={(currentPage === 'Videos') ? 'admin-navbar-menu-list-item current' : 'admin-navbar-menu-list-item'}>
                                <VideoLibraryIcon />  
                                <span>Videos</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to='/admin/artistDashboard'>
                            <div className={(currentPage === 'Artists') ? 'admin-navbar-menu-list-item current' : 'admin-navbar-menu-list-item'}>
                                <PersonIcon />  
                                <span>Artists</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to='/admin/userDashboard'>
                            <div className={(currentPage === 'Users') ? 'admin-navbar-menu-list-item current' : 'admin-navbar-menu-list-item'}>
                                <AccountCircleIcon />  
                                <span>Users</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to='/'>
                            <div className='admin-navbar-menu-list-item'>
                                <KeyboardReturnIcon />  
                                <span>Main Site</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        {/* Change path */}
                        <Link to='/admin' onClick={logout}>
                            <div id='logout' className='admin-navbar-menu-list-item'>
                                <LogoutIcon />  
                                <span>Log Out</span>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

AdminNavbar.defaultProps = {
    adminName: 'Administrator',
    currentPage: 'dashboard',
    sidebarStatus: true
}

export default AdminNavbar;