import '../css/Loading.css';

import React from 'react';
import ReactLoading from 'react-loading';

export const LoadingPage = ({ color='black' }) => {

    return (
        <div className="loading-page" data-testid="loading-page">
            <ReactLoading type="spin" color={color} height={100} width={100} />
        </div>
    )
}

export const LoadingSection = ({ color='black' }) => {

    return (
        <div className="loading-section" data-testid="loading-section">
            <ReactLoading type="spin" color={color} height={75} width={75} />
        </div>
    )
}
