import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../context/UserContext';
import { Navigate, Link } from 'react-router-dom';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminArtistPage.css'

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';
import AdminArtistList from '../../components/admin-components/AdminArtistList';

import AddCircleIcon from '@mui/icons-material/AddCircle';

const AdminArtistPage = () => {
    const [sidebar, setSidebar] = useState(true);
    const [popularArtist, setPopularArtist] = useState([])
    const [artistQuery, setArtistQuery] = useState("");
    const [artistSearch, setArtistSearch] = useState("");
    const [update, setUpdate] = useState(true);

    // Get information about the currently logged in user, if any
    const { user, logout } = useUserContext();

    useEffect(() => {
        async function fetchData() {
            const featuredArtists = await adminApiHelpers.retrieveFeaturedArtist(user);

            if (featuredArtists.status !== STATUS_CODES.SUCCESS) {
                console.log("Error")
            } else {
                setPopularArtist(featuredArtists.data);
            }
        }

        fetchData();
    }, [user, update])

    const showSidebar = () => { setSidebar(!sidebar); }
    const refresh = () => { setUpdate(!update) }

    const handleQuery = (e) => {
        e.preventDefault()

        setArtistQuery(e.target.value);
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            submitQuery();
        }
    }

    const submitQuery = () => {
        setArtistSearch(artistQuery);
    }

    // If user isn't logged in or is not an admin, redirect to admin home page
    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Artists" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                <div className='artist-page-header'>
                    <div>
                        <h1>Artists</h1>
                        <p>Dashboard</p>
                    </div>
                </div>
                <div className='featured-artist-section'>
                    <div className='featured-artist-section-top'>
                        <p className='boxed-text'>Featured Artists</p>
                    </div>
                    <div className='artist-portraits-section'>
                        {popularArtist.map((item,index) => {
                                return (
                                    <div key={index} className='artist-picture'>
                                        <Link to={'/admin/editArtist/' + item.id} 
                                            state={{ artistId: item.id}} style={{textDecoration: "none"}}
                                        >
                                            <img src={item.image} className='featured-artist-image' alt={item.name}/>
                                            <p>{item.name}</p>
                                        </Link>
                                    </div>
                                )
                            }
                        )}
                        {/* <div className='artist-picture'>
                            <Link className='artist-picture' to='/admin/newArtist'>
                                <AddCircleIcon className='add-artist' sx={{ fontSize: 160 }}/>
                                <p>Add Artist</p>
                            </Link>
                        </div> */}
                    </div>
                </div>
                <div className='artist-search-container'>
                    <p className='boxed-text'>All Artists</p>
                    <p style={{ marginTop: '0.5rem' }}>Add, edit, delete, and view analytics for all artists</p>
                    <div className='artist-search-and-add-container'>
                        <div>
                            <input 
                                className='search-input' type='text' onKeyDown={handleEnter}
                                placeholder='Find an artist' onChange={handleQuery}>
                            </input>
                            <button onClick={submitQuery} className='quick-action-button'>Search</button>
                        </div>
                        <Link to='/admin/newArtist' style={{ textDecoration: 'none' }}>
                            <p className='quick-action-button' style={{ maxWidth: '50%' }}>Add Artist</p>
                        </Link>
                    </div>
                    <AdminArtistList artistQuery={artistSearch} user={user} refresh={refresh}/>
                </div>
            </div>
            
        </>
    );
}

export default AdminArtistPage;