import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import '../../css/admin-css/AdminUsersPage.css'

const AdminListOfAdmins = ({ adminList }) => {
    return (
        <>
            <ul className='list-of-admins-container'>
                <li className='list-of-admins-admin-entry'>
                    <p className='bold'>Name</p>
                    <p className='bold'>Email Address</p>
                    {/* <p className='bold'>Actions</p> */}
                </li>
                {adminList.map((item, index) => {
                    return (
                        <li key={index} className='list-of-admins-admin-entry'>
                            <span>{item.name}</span>
                            <span>{item.email}</span>
                            {/* <div className='list-entry__actions-container'>
                                <div>
                                    <EditIcon />
                                </div>
                                <div>
                                    <DeleteIcon style={{ color: 'red' }}/>
                                </div>
                            </div> */}
                        </li>
                    )
                })}
            </ul>
        </>
    );
}

export default AdminListOfAdmins;