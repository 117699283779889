import React, { useState } from 'react';

import '../../css/admin-css/AdminForms.css'
import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminConfirmDelete.css'
import adminApiHelpers from '../../helpers/adminApiHelpers';
import { STATUS_CODES } from '../../helpers/apiHelpers';

const ConfirmVideoDelete = ({ trigger, showConfirmation, videoInfo, doDelete, user }) => {
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [colorMessage, setColorMessage] = useState('red');

    const deleteVideo = (video) => {
        async function removeVideo(video) {
            const query = "" + video.id;
            const result = await adminApiHelpers.deleteVideo(query, user);

            if (result.status !== STATUS_CODES.SUCCESS) {
                setMessage("Error deleting video");
                setColorMessage('red');
                temporaryShow();
            } else {
                setMessage("Video Deleted Successfully");
                setColorMessage('green');
                temporaryShow();
                setTimeout(() => {
                    showConfirmation();
                    doDelete();
                }, 3000);
            }
        }

        removeVideo(video);
    }

    const temporaryShow = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);
    }

    const showDeleteResult = () => {
        return showMessage ? (
            <div className={`message-box ${colorMessage}`}>
                <p>{message}</p>
            </div>
        ) : "";
    }
    
    return (trigger) ? (
        <>
            <div className='popup-background'>
                <div className='popup-container-for-delete-video'>
                    <div className='remove-popup-container'>
                        <button className='remove-popup-button' onClick={showConfirmation}>&#10006;</button>
                    </div>
                    <h1>Confirm Video Deletion</h1>
                    <div className='user-info-container'>
                        <p className='user-info'>Video: {videoInfo.title}</p>
                        <img src={videoInfo.thumbnail} />
                    </div>
                    <div className='confirm-delete-message'>
                        <p>Are you sure you want to delete this video?</p>
                        <p>This video will remain on YouTube</p>
                    </div>
                    <div className='button-section'>
                        <button onClick={() => deleteVideo(videoInfo)} className='quick-action-button increase-size'>Delete Video</button>
                    </div>
                    {showDeleteResult()}
                </div>
            </div>
        </>
    ) : "";
}

export default ConfirmVideoDelete;