import '../css/Error.css'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';

const ErrorMessage = (props) => {

    const { errorMessage } = props

    if (errorMessage) {
        return (
            <div className="error-box">
                <p className="error-message">{errorMessage}</p>
            </div>
        )
    } else {
        return null
    }
}

export const ErrorSection = ({ errorMessage }) => {

    if (errorMessage) {
        return (
            <div className="error-section">
                <ErrorOutlineIcon className="error-icon" htmlColor='inherit' fontSize="large"/>
                <ErrorMessage errorMessage={errorMessage} />
            </div>
        )
    } else {
        return null
    }
}

export const ErrorPage = ({ errorMessage }) => {

    if (errorMessage) {
        return (
            <div className="error-page user-page">
                <ErrorOutlineIcon className="error-icon" htmlColor='inherit' fontSize="large"/>
                <ErrorMessage errorMessage={errorMessage} />
            </div>
        )
    } else {
        return null
    }
}

export default ErrorMessage
