import React, { useState } from 'react';
import { validateBirthday, validateEmail, validatePassword } from '../helpers/fieldValidators';

import Button from '../components/Button';
import CheckBox from './Checkbox';
import ErrorMessage from './Error';
import InputField from './InputField';
import { STATUS_CODES } from '../helpers/apiHelpers';
import { UserAuthStateEnum } from './UserAuthModal';
import { useUserContext } from "../context/UserContext";

const UserRegisterForm = (props) => {

    const { register } = useUserContext();

    const { updateFormState, handleClose } = props

    const [isLoading, setIsLoading] = useState(false)

    const [messages, setMessages] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        zipCode: '',
        birthday: '',
        api: '',
    })

    // Get date from 13 years ago
    const now = new Date()
    const latestDate = new Date(now.getFullYear() - 13, now.getMonth(), now.getDate()).toISOString().split('T')[0]

    const validateFields = (firstName, lastName, email, password, confirmPassword, zipCode, birthday) => {

        const fieldErrors = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            zipCode: '',
            birthday: '',
        }

        if (!firstName) {
            fieldErrors.firstName = 'First name is required'
        }

        if (!lastName) {
            fieldErrors.lastName = 'Last name is required'
        }

        if (!email) {
            fieldErrors.email = 'Email is required'
        } else if (!validateEmail(email) && email !== 'user' && email !== 'admin') {
            // user and admin are for testing purposes, remove when backend is ready
            fieldErrors.email = 'Please enter a valid email address'
        }

        if (!password) {
            fieldErrors.password = 'Password is required'
        } else if (!validatePassword(password)) {
            fieldErrors.password = 'Password does not meet requirements: 1 uppercase, 1 lowercase, 1 number, 1 special character, 8 characters'
        }

        if (!confirmPassword) {
            fieldErrors.confirmPassword = 'Please confirm your password'
        } else if (password !== confirmPassword) {
            fieldErrors.confirmPassword = 'Passwords do not match'
        }

        if (!zipCode) {
            fieldErrors.zipCode = 'Zip code is required'
        }

        if (!birthday) {
            fieldErrors.birthday = 'Birthday is required'
        } else if (!validateBirthday(birthday)) {
            fieldErrors.birthday = 'You are not old enough to create an account.'
        }

        return fieldErrors

    }

    const onSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)

        const firstName = document.getElementById('first-name').value
        const lastName = document.getElementById('last-name').value
        const email = document.getElementById('email').value
        const password = document.getElementById('password').value
        const confirmPassword = document.getElementById('confirm-password').value
        const zipCode = document.getElementById('zipcode').value
        const birthday = document.getElementById('birthday').value
        const emailUpdates = document.getElementById('emailUpdates').checked

        const fieldErrors = validateFields(firstName, lastName, email, password, confirmPassword, zipCode, birthday)
        const valid = Object.values(fieldErrors).every(field => field === '')

        if (!valid) {
            setMessages({...fieldErrors, api: ''})
            setIsLoading(false)
            return
        }

        // Call register API
        const result = await register(email, password, firstName, lastName, birthday, zipCode, emailUpdates)

        if (result.status !== STATUS_CODES.POST_SUCCESS) {
            setMessages({...fieldErrors, api: result.message})
            setIsLoading(false)
        } else {
            handleClose()
        }

    }



    return (
        <div>
            <Button
                type="no-style"
                onClick={handleClose}
                className="close-btn"
                text="Close"
            />
            <div className="register-form__header">
                <h1>Sign Up</h1>
                <ErrorMessage errorMessage={messages.api} />
            </div>
            <form id="register-form">
                <div className="name-fields">
                    <InputField
                        id="first-name"
                        type="text"
                        placeholder="First Name"
                        disabled={isLoading}
                        error={messages.firstName}
                        compact
                    />
                    <InputField
                        id="last-name"
                        type="text"
                        placeholder="Last Name"
                        disabled={isLoading}
                        error={messages.lastName}
                        compact
                    />
                </div>
                <InputField
                    id="email"
                    type="text"
                    label="Email"
                    placeholder="Email Address"
                    disabled={isLoading}
                    error={messages.email}
                    compact
                />
                <CheckBox
                    id="emailUpdates"
                    text="Subscribe to Nashville Insider newsletter"
                    initialValue={true}
                    onChange={() => {}}
                />
                <InputField
                    id="password"
                    type="password"
                    placeholder="Password"
                    disabled={isLoading}
                    error={messages.password}
                    compact
                />
                <InputField
                    id="confirm-password"
                    type="password"
                    placeholder="Confirm Password"
                    disabled={isLoading}
                    error={messages.confirmPassword}
                    compact
                />
                <InputField
                    id="zipcode"
                    type="text"
                    label="Zip Code"
                    placeholder="12345"
                    disabled={isLoading}
                    error={messages.zipCode}
                    compact
                />
                <InputField
                    id="birthday"
                    type="date"
                    label="Birthday"
                    placeholder="MM/DD/YYYY"
                    disabled={isLoading}
                    error={messages.birthday}
                    max={latestDate}
                    compact
                />
            <div className="form-controls">
                <Button
                    text="SIGN UP"
                    onClick={onSubmit}
                    disabled={isLoading}
                    type="primary"
                    isSubmit
                />
                <Button
                    customText={<p>Already have an account? <strong>Sign In</strong></p>}
                    onClick={() => updateFormState(UserAuthStateEnum.LOGIN)}
                    disabled={isLoading}
                />
            </div>
        </form>
    </div>)
}

export { UserRegisterForm };
