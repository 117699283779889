import '../css/VideoCategory.css';

import { Link } from 'react-router-dom';
import React from 'react';

const VideoThumbnail = (props) => {

    const { video } = props;

    return (
        <Link
            className="videos__video-thumbnail"
            to={`/videos/${video.id}`}
        >
            <img
                src={video.thumbnail}
                alt="Video thumbnail"
                className="video-thumbnail__image"
            />
            <p title={video.title}>{video.title}</p>
        </Link>
    )
}

const VideoCategory = ({ id, title, videos }) => {

    const screenWidth = window.innerWidth;
    const maxVideos = Math.min(Math.ceil(screenWidth / 85), 10);

    return (
        <div className="video-category">
            <h3 className="video-category__title">{title}</h3>
            <div className="video-category__videos">
                {videos.slice(0, Math.min(maxVideos, videos.length)).map((video, index) => {
                    return <VideoThumbnail key={index} video={video} />
                    }
                )}
                {/* Since the For You category isn't a traditional tag, view all should never appear for it */}
                {id !== 10 &&
                    <Link to={`/categories/${id}`} className="video-category__view-all">
                        <p>View All</p>
                    </Link>
                }
            </div>
        </div>
    )

}

export default VideoCategory;
