import '../css/FeaturedVideo.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import Button from './Button';
import Modal from 'react-modal';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VideoTags from '../components/VideoTags';
import getIFrame from '../helpers/getIFrame';

const DetailsModal = ({ video, closeModal }) => {

    const { title, description, id, tags } = video || {};
    const navigate = useNavigate();

    return (
        <div className="details-modal">
            <div className="details-modal__content">
                <div className="details-modal__header">
                    <Button
                        text="WATCH"
                        type="primary"
                        icon={<PlayArrowIcon />}
                        onClick={() => navigate(`/videos/${id}`)}
                    />
                    <Button
                        text="Close"
                        id="close-btn"
                        type="no-style"
                        onClick={closeModal}
                        test_id="close-modal"
                    />
                </div>
                <div className="details-modal__video-title">
                    <h2>{title}</h2>
                </div>
                <div className="details-modal__body">
                    <div className="details-modal__description">
                        <p>{description}</p>
                    </div>
                </div>
                {tags && tags.length > 0 ? <VideoTags tags={tags} /> : null}
            </div>
        </div>
    )
}

const FeaturedVideo = ({ video }) => {

    const { episode_number, youtube_id, id } = video || {};

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const getVideo = () => {

        if (!video) {
            return (
                <>
                    <p>Loading...</p>
                </>
            )
        } else {
            return (
                <>
                    {getIFrame(youtube_id)}
                </>
            )
        }

    }

    const getVideoDetails = () => {

        if (!video) {
            return (
                <>
                    <p>Loading...</p>
                </>
            )
        }

        return (
            <div className="default-details">
                <h1 id="this-week">THIS WEEK ON</h1>
                <h1 id="nashville-insider">NASHVILLE INSIDER</h1>
                {episode_number && <h2 id="subheading">EPISODE {episode_number}</h2>}
            </div>
        )

    }

    return (
        <div className="featured-video">
            <div className="featured-video__video">
                {getVideo()}
            </div>
            <div className="featured-video__details">
                {getVideoDetails()}
                <div className="featured-video__buttons">
                    <Link to={video ? `/videos/${id}` : ""}>
                        <Button
                            text="WATCH" type="primary"
                            icon={<PlayArrowIcon fontSize='medium'/>}
                        />
                    </Link>
                    <Button text="DETAILS" type="secondary" onClick={() => setModalIsOpen(!modalIsOpen)}/>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className="details-modal"
                overlayClassName="details-modal__overlay"
            >
                {video ? <DetailsModal video={video} closeModal={() => setModalIsOpen(false)} /> : null}
            </Modal>
        </div>
    )

}

export default FeaturedVideo;
