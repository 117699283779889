import React, { useState } from 'react'
import { useUserContext } from '../../context/UserContext';
import { Navigate, useNavigate } from 'react-router-dom'
import { STATUS_CODES } from '../../helpers/apiHelpers';
import { validateEmail } from '../../helpers/fieldValidators';

import '../../css/admin-css/AdminLogin.css';

import AdminForgotPssw from '../../components/admin-components/AdminForgotPssw';

const AdminLoginPage = () => {

    const { login } = useUserContext();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [showForgotPssw, setShowForgotPssw] = useState(false);

    // Get information about the currently logged in user, if any
    const { user } = useUserContext();

    const [errorMessage, setErrorMessage] = useState(null)

    const navigate = useNavigate();

    const showForgotPsswPopup = () => { setShowForgotPssw(!showForgotPssw); }

    // If user is logged in and an admin, redirect to admin home page
    if (user && user.isAdmin) {
        return <Navigate to="/admin/dashboard" />
    }

    // Submit login request
    const handleSubmit = async (e) => {
        e.preventDefault()

        async function submit(credentials) {
            const loginResult = await login(username, password);

            if (loginResult.status !== STATUS_CODES.POST_SUCCESS && loginResult.status !== STATUS_CODES.SUCCESS) {
                setErrorMessage('Error logging in');
                return false;
            } else {
                navigate('/admin/dashboard');
            }
        }
        
        if (!username || !password) {
            setErrorMessage('Please fill out all fields.')
            return false
        }

        if (!validateEmail(username)) {
            setErrorMessage("Incorrect email format")
            return false
        }

        const loginInfo = {
            email: username,
            password: password
        }

        submit(loginInfo);
        return true
    }

    // Update login credentials
    const changeForm = (e) => {
        e.preventDefault()

        const {name, value } = e.target 

        switch (name) {
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
        }
    }

    // Error message display
    const showErrorMessage = () => {
        return (
            <div className={(errorMessage === null) ? 'error-display' : 'error-display active'}>
                <p>{errorMessage}</p>
                <p 
                    onClick={() => setErrorMessage(null)} 
                    className={(errorMessage === null) ? 'remove-error' : 'remove-error active'}
                >&#10006;</p> 
            </div>
        );
    }

    return (
        <>
            <div className='main-body'>
                <div className='login-box'>
                    <div className='logo-section'>
                        <img src={require('../../assets/small-logo.png')}/>
                        <h3>Media Viewer</h3>
                    </div>
                    <form className='login-data' onSubmit={e => handleSubmit(e)} >
                        <p>Email Address</p>
                        <input 
                            type='text' name='username'
                            value={username} onChange={changeForm}>
                        </input>
                        <br/>
                        <p>Password</p>
                        <input 
                            type='password' name='password'
                            value={password} onChange={changeForm}>
                        </input>
                        {showErrorMessage()}
                        {/* <label className='checkBox'>
                            <input type='checkbox'></input>
                            Remember Me
                        </label> */}
                        <div className='submit-section'>
                            <p onClick={showForgotPsswPopup}>Forgot Password?</p>
                            <button type='submit'>Log In</button>
                        </div>
                    </form>
                    <a className='back-to-main-site' href="/">&#8592; Back to Nashville Insider</a>
                </div>
                <AdminForgotPssw 
                    trigger={showForgotPssw}
                    showThePopup={showForgotPsswPopup}
                />
            </div>
        </>
    );
}

export default AdminLoginPage;
