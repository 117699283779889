import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../context/UserContext';
import { Navigate } from 'react-router-dom'
import { STATUS_CODES } from '../../helpers/apiHelpers';
import { validateEmail } from '../../helpers/fieldValidators';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminForms.css'

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';
import AdminUpdatePsswPopup from '../../components/admin-components/AdminUpdatePsswPopup';


const AdminOwnAccount = () => {
    const [sidebar, setSidebar] = useState(true);
    const [showUpdatePssw, setShowUpdatePssw] = useState(false);
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [colorMessage, setColorMessage] = useState('red');
    const [adminInfo, setAdminInfo] = useState({
        fName: '',
        lName: '',
        email: ''
    });

    // Get information about the currently logged in user, if any
    const { user, logout } = useUserContext();

    // Get current admin data
    useEffect(() => {
        async function fetchData() {
            const result = await adminApiHelpers.getAdminInfo(user.id, user)
            
            if (result.status !== STATUS_CODES.SUCCESS) {
                sendMessage("Error loading admin info", "red");
            } else { 
                setAdminInfo({
                    fName: result.data.data.first_name,
                    lName: result.data.data.last_name,
                    email: result.data.data.email
                })
            }
        }

        fetchData();
    }, [user])

    const showSidebar = () => { setSidebar(!sidebar); }
    const showPsswPopup = () => { setShowUpdatePssw(!showUpdatePssw); }

    const sendMessage = (message, color) => {
        setMessage(message)
        setColorMessage(color);
        temporaryShow();
    }

    const temporaryShow = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);
    }

    const showResult = () => {
        return showMessage ? (
            <div className={`message-box ${colorMessage}`}>
                <p>{message}</p>
            </div>
        ) : "";
    }

    const changeForm = (e) => {
        e.preventDefault()

        const {name, value } = e.target 

        switch (name) {
            case 'fName':
                setAdminInfo(prevState => ({
                    ...prevState,
                    fName: value
                }));
                break;
            case 'lName':
                setAdminInfo(prevState => ({
                    ...prevState,
                    lName: value
                }));
                break;
            case 'email':
                setAdminInfo(prevState => ({
                    ...prevState,
                    email: value
                }));
                break;
            default:
                break;
        }
    }

    const submitForm = (e) => {
        e.preventDefault();

        async function submit(changes) {
            const result = await adminApiHelpers.updateAdmin(changes, user);

            if (result.status !== STATUS_CODES.SUCCESS) {
                sendMessage("Error updating information", "red")
            } else {
                sendMessage("Information Updated Successfully", "green")
            }
        }

        if (!validateEmail(adminInfo.email)) {
            sendMessage("Email given was not found", "red")
            return;
        }

        submit(adminInfo)
    }

    // If user isn't logged in or is not an admin, redirect to admin home page
    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Users" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                <h1>Account</h1>
                <div className='account-container'>
                    <div className='boxed-text-container'>
                        <p className='boxed-text'>Credentials</p>
                    </div>
                    <div className='form-container'>
                        <div className='input-container'>
                            <p className='bold'>First Name</p>
                            <input 
                                className='form-text-input' type='text' placeholder={adminInfo.fName}
                                name='fName' value={adminInfo.fName} onChange={changeForm}
                            />
                        </div>
                        <div className='input-container'>
                            <p className='bold'>Last Name</p>
                            <input 
                                className='form-text-input' type='text' placeholder={adminInfo.lName}
                                name='lName' value={adminInfo.lName} onChange={changeForm}
                            />
                        </div>
                        <div className='input-container'>
                            <p className='bold'>Email</p>
                            <input 
                                className='form-text-input' type='text' placeholder={adminInfo.email}
                                name='email' value={adminInfo.email} onChange={changeForm}
                            />
                        </div>
                    </div>
                    <div className='password-section-container'>
                        <button onClick={showPsswPopup} className='quick-action-button bigger-font'>Update Password</button>
                        <button onClick={submitForm} className='quick-action-button bigger-font'>Save</button>
                        {showResult()}
                    </div>
                    <AdminUpdatePsswPopup 
                        trigger={showUpdatePssw} showConfirmMessage={sendMessage}
                        showThePopup={showPsswPopup} admin={user}
                    />
                </div>
            </div>
        </>
    );
}

export default AdminOwnAccount;