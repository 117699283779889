export const fakePopularVideos = [
    {
        id: 0,
        title: 'Video Title',
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    },
    {
        id: 1,
        title: 'Video Title',
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    },
    {
        id: 3,
        title: 'Video Title',
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    },
    {
        id: 4,
        title: 'Video Title',
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    },
    {
        id: 5,
        title: 'Video Title',
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    }
]

export const fakePopularArtists = [
    {
        id: 0,
        name: 'Blake Shelton',
        picture: 'https://www.warnermusicnashville.com/sites/g/files/g2000010586/files/bs-WMNsite-750.jpeg',
        image: 'https://www.warnermusicnashville.com/sites/g/files/g2000010586/files/bs-WMNsite-750.jpeg'
    },
    {
        id: 1,
        name: 'Carrie Underwood',
        picture: 'https://www.biography.com/.image/t_share/MTgwNDU4ODM0NjUxNzg0MzQy/gettyimages-1183642471-copy.jpg',
        image: 'https://www.biography.com/.image/t_share/MTgwNDU4ODM0NjUxNzg0MzQy/gettyimages-1183642471-copy.jpg'
    },
    {
        id: 2,
        name: 'Keith Urban',
        picture: 'https://www.biography.com/.image/t_share/MTkyMzE2NTE1OTIyNDg2ODMw/keith-urban-gettyimages-1392268289.jpg',
        image: 'https://www.biography.com/.image/t_share/MTkyMzE2NTE1OTIyNDg2ODMw/keith-urban-gettyimages-1392268289.jpg'
    },
    {
        id: 3,
        name: 'Tim McGraw',
        picture: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Tim_McGraw_October_24_2015.jpg/220px-Tim_McGraw_October_24_2015.jpg',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Tim_McGraw_October_24_2015.jpg/220px-Tim_McGraw_October_24_2015.jpg'
    },
    {
        id: 4,
        name: 'Luke Bryan',
        picture: 'https://ca-times.brightspotcdn.com/dims4/default/e9ff582/2147483647/strip/true/crop/2160x1536+0+0/resize/1486x1057!/quality/80/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Faf%2Fbe%2F4a797b8f496d999d9863b8cd7b35%2Fla-ca-luke-bryan.jpg'
    },
    {
        id: 5,
        name: 'Dolly Parton',
        picture: 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Young-Dolly-Parton.jpg'
    },
    {
        id: 6,
        name: 'Brad Paisley',
        picture: 'https://i.iheart.com/v3/surl/aHR0cDovL2ltYWdlLmloZWFydC5jb20vaW1hZ2VzL292ZXJyaWRlLzg5NjY1XzhmYmVlMDE4LTczMzEtNGUzNi04MmE5LTIzYWVlYzM1OTBlZS5qcGc=?ops=fit%28720%2C720%29&sn=eGtleWJhc2UyMDIxMTExMDqduyuxaqRjvcw8OW3BcFcGedFp129xAYtu6MElHHcG7A%3D%3D&surrogate=1cOXl179JY-syhxYSCX6Q1a_Mcu6UO8d-F4oJzpZf1hcUbJr4aItzdcPFFetygNPhRL_jp-aacbYJBv3M6zCjrxd85v9toTS_Xf9X7C9TwhYQWXwo1KwIcuiLA-L9i8hvzvYnmJ3e7rvEwbWIU3kPsgRRu6ISVtXM0zfpSDds2TmdP6kt7Fhit1QNQE7M90SGZsIEbsAgK2RZ9wLNKb9xAqNHZZsmyoNnuFiiojfq5So4q36svWAF18TXDlIkttx'
    }
]

export const fakeAdminList = [
    {
        id: 0,
        name: 'Lorem Ipsum',
        email: 'loremipsum@gmail.com'
    },
    {
        id: 1,
        name: 'Michael Glynn',
        email: 'loremipsum@gmail.com'
    },
    {
        id: 2,
        name: 'Michelle Lara',
        email: 'loremipsum@gmail.com'
    },
    {
        id: 3,
        name: 'Lorem Ipsum',
        email: 'loremipsum@gmail.com'
    },
    {
        id: 4,
        name: 'Lorem Ipsum',
        email: 'loremipsum@gmail.com'
    },
    {
        id: 5,
        name: 'Lorem Ipsum',
        email: 'loremipsum@gmail.com'
    },
    {
        id: 6,
        name: 'Lorem Ipsum',
        email: 'loremipsum@gmail.com'
    },
    {
        id: 7,
        name: 'Lorem Ipsum',
        email: 'loremipsum@gmail.com'
    }
]

export const fakeUserList = [
    {
        id: 0,
        name: 'User Name',
        email: 'emailaddress@gmail.com',
        verified: true,
        login: '11/11/1111 11:11 AM EST'
    },
    {
        id: 1,
        name: 'Charles Young',
        email: 'emailaddress@gmail.com',
        verified: false,
        login: '11/11/1111 11:11 AM EST'
    },
    {
        id: 2,
        name: 'Caroline Charles',
        email: 'emailaddress@gmail.com',
        verified: false,
        login: '11/11/1111 11:11 AM EST'
    },
    {
        id: 3,
        name: 'User Name',
        email: 'emailaddress@gmail.com',
        verified: false,
        login: '11/11/1111 11:11 AM EST'
    },
    {
        id: 4,
        name: 'User Name',
        email: 'emailaddress@gmail.com',
        verified: true,
        login: '11/11/1111 11:11 AM EST'
    },
    {
        id: 5,
        name: 'User Name',
        email: 'emailaddress@gmail.com',
        verified: true,
        login: '11/11/1111 11:11 AM EST'
    },
    {
        id: 6,
        name: 'User Name',
        email: 'emailaddress@gmail.com',
        verified: false,
        login: '11/11/1111 11:11 AM EST'
    },
    {
        id: 7,
        name: 'User Name',
        email: 'emailaddress@gmail.com',
        verified: false,
        login: '11/11/1111 11:11 AM EST'
    },
    {
        id: 8,
        name: 'User Name',
        email: 'emailaddress@gmail.com',
        verified: false,
        login: '11/11/1111 11:11 AM EST'
    },
    {
        id: 9,
        name: 'User Name',
        email: 'emailaddress@gmail.com',
        verified: true,
        login: '11/11/1111 11:11 AM EST'
    }
]

export const fakeAdmin = {
    id: 1,
    fName: 'Michael',
    lName: 'Glynn',
    email: 'glynnMichaelExample@gmail.com',
    password: 'password'
}

export const fakeVideo = {
    id: 5,
    link: 'https//:example.youtu.be.com',
    title: 'The Award Show',
    epNum: "1",
    description: "Talk about the award show",
    public: true,
    tags: ["Trending", "Season 2", "Award Show"]
}

export const fakeTags = [
    {
        id: 0,
        tagName: ''
    },
    {
        id: 1,
        tagName: 'Season 1'
    },
    {
        id: 2,
        tagName: 'Season 2'
    },
    {
        id: 3,
        tagName: 'Blake Sheldon'
    },
    {
        id: 4,
        tagName: 'Country Music Awards'
    },
    {
        id: 5,
        tagName: 'Trending'
    }
]

export const fakeVideos = [
    {
        id: 0,
        title: 'Video Title',
        date: 'Jan 12, 2019',
        public: true,
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    },
    {
        id: 1,
        title: 'Award Show Highlights',
        date: 'Jan 12, 2019',
        public: true,
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    },
    {
        id: 2,
        title: 'Award Show',
        date: 'Jan 12, 2019',
        public: false,
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    },
    {
        id: 3,
        title: 'Blake Sheldon\'s wedding',
        date: 'Jan 12, 2019',
        public: true,
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    },
    {
        id: 4,
        title: 'blake sheldon\'s wedding',
        date: 'Jan 12, 2019',
        public: true,
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    },
    {
        id: 5,
        title: 'The Award Show',
        date: 'Jan 12, 2019',
        public: true,
        thumbnail: 'https://billanderson.com/wired/wp-content/uploads/2020/05/BANashInsider-1160x665.png'
    }
]

export const layoutTags = [
    {
        id: '10',
        name: 'Featured'
    },
    {
        id: '11',
        name: 'Country Music Awards'
    },
    {
        id: '12',
        name: 'Tim McGraw'
    },
    {
        id: '13',
        name: 'Season 5'
    },
    {
        id: '14',
        name: 'Season 6'
    },
    {
        id: '15',
        name: 'Blake Sheldon'
    }
]
    
