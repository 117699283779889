import React from 'react';
import { Link } from 'react-router-dom';

import '../../css/admin-css/AdminHomeLists.css'

const AdminHomeArtistList = ({ artistList }) => {
    return (
        <>
            <div className='order-list'>
                <ul className='video-or-artist-list'>
                    {artistList.map((item, index) => {
                        return (
                            <Link to={'/admin/editArtist/' + item.artistid} 
                                state={{ artistId: item.artistid}} key={index} 
                                style={{ color: "black" ,textDecoration: "none"}}
                            >
                                <li className='artist-entry' data-testid='home-artist'>
                                    <h4>{index + 1}</h4>
                                    <img className='artist-thumbnail' src={item.img_path}/>
                                    {item.name}
                                </li>
                            </Link>
                        )
                    })}
                </ul>
            </div>
        </>
    );
}

export default AdminHomeArtistList;