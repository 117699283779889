import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom'
import { useUserContext } from '../../context/UserContext';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminVideos.css'

import InfoIcon from '@mui/icons-material/Info';

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';
import AdminLayoutForm from '../../components/admin-components/AdminLayoutForm';
import AdminListOfVideos from '../../components/admin-components/AdminListOfVideos';
import AdminAddLayout from '../../components/admin-components/AdminAddLayout';

const AdminVideosPage = () => {

    const { user, logout } = useUserContext();

    const [sidebar, setSidebar] = useState(true);
    const [currentQuery, setCurrentQuery] = useState('');
    const [videoQuery, setVideoQuery] = useState('');
    const [tagList, setTagList] = useState([]);
    const [addCategory, setAddCategory] = useState(false);
    const [featuredVideo, setFeaturedVideo] = useState({
        title: '',
        link: ''
    });

    const [errorMessage, setErrorMessage] = useState(null);
    const [errorColor, setErrrorColor] = useState("red");

    // Get Tag Layout
    useEffect(() => {
        async function fetchData() {
            const results = await adminApiHelpers.retrieveLayout(user);

            if (results.status !== STATUS_CODES.SUCCESS) {
                console.log("Error retrieving tags");
            } else {
                setTagList(results.data.tags);
            }
        }

        fetchData();

    }, [user])

    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    const showSidebar = () => { setSidebar(!sidebar); }
    const showAddCategory = () => { setAddCategory(!addCategory); }

    const changeTagList = (newList) => {
        setTagList(newList);
    }

    const changeQuery = (e) => {
        e.preventDefault()

        setCurrentQuery(e.target.value);
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            submitQuery(e);
        }
    }

    const submitQuery = (e) => {
        setVideoQuery(currentQuery);
    }

    const updateLayout = () => {
        async function sendNewLayout() {
            const confirmation = await adminApiHelpers.updateLayout(tagList, user);

            if (confirmation.status !== STATUS_CODES.SUCCESS) {
                setErrrorColor("red");
                setErrorMessage("Error updating layout");
            } else {
                setErrrorColor("green");
                setErrorMessage("Layout updated successfully");
            }
        }

        sendNewLayout();
    }

    const addLayout = (newTag) => {
        let newestTag = {
            id: parseInt(newTag.id, 10),
            name: newTag.name,
        }

        setTagList(newTags => [...newTags, newestTag])
    }

    const updateFeaturedVideo = () => {
        async function updateMainPage() {
            const result = await adminApiHelpers.updateFeaturedVideo(featuredVideo, user);

            if (result.status !== STATUS_CODES.POST_SUCCESS && result.status !== STATUS_CODES.SUCCESS) { 
                setErrrorColor("red");
                setErrorMessage("Error updating featured video");
            } else {
                setErrrorColor("green");
                setErrorMessage("Featured Video Updated");
            }
        }

        updateMainPage();
    }

    const changeForm = (e) => {
        e.preventDefault()

        const {name, value } = e.target;

        switch (name) {
            case 'title':
                setFeaturedVideo(video => ({
                    ...video,
                    title: value
                }));
                break;
            case 'link':
                setFeaturedVideo(video => ({
                    ...video,
                    link: value
                }));
                break;
            default:
                break;
        }
    }

    const showErrorMessage = () => {
        return (
            <div className={(errorMessage === null) ? 'form-error' : `form-error active ${errorColor}`}>
                <p>{errorMessage}</p>
                <p 
                    onClick={() => {
                        setErrorMessage(null);
                        setErrrorColor("red");
                    }} 
                    className={(errorMessage === null) ? 'remove-error' : 'remove-error active'}
                >&#10006;</p> 
            </div>
        );
    }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Videos" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                <div className='video-dashboard-header-container'>
                    <div>
                        <h1>Videos</h1>
                        <p>Dashboard</p>
                    </div>
                </div>
                <div className='video-dashboard-upper-half-container'>
                    <div>
                        <p className='boxed-text'>Layout</p>
                        <p>Edit the category order on the home page</p>
                        <p className='italic-text'><InfoIcon /> Click and drag to change order</p>
                        <div className='tag-list-container'>
                            <div className='layout-options'>
                                <button onClick={updateLayout} className='quick-action-button'>Update Layout</button>
                                <button onClick={showAddCategory} className='transparent-button'>+ Add Category</button>
                                <AdminAddLayout 
                                    trigger={addCategory} addLayout={addLayout}
                                    showThePopup={showAddCategory} user={user}
                                />
                            </div>
                            <div className='tag-list'>
                                <AdminLayoutForm tagList={tagList} setTagList={changeTagList}/>
                            </div>
                        </div>
                    </div>
                    <div className='featured-video-section'>
                        <p className='boxed-text'>Featured Video</p>
                        <p>Change the featured video on the video homepage</p>
                        {/* <p className='italic-text'><InfoIcon />Leave this section empty to automatically use most recent 'Nashville Insider' episode</p> */}
                        <div className='feature-and-link-video-container'>
                            <p className='bold'>Featured Video</p>
                            <input 
                                className='search-input long' type="text" 
                                placeholder='Enter title of video'
                                name='title'
                                value={featuredVideo.title} onChange={changeForm}
                            ></input>
                            {/* <div className='checkbox-container'>
                                <input type="checkbox"></input>
                                <span>Link "Watch" to different video</span>
                            </div> */}
                            <p className='bold'>Linked Video</p>
                            <input 
                                className='search-input long' type="text" name='link'
                                placeholder='Enter link of video' 
                                value={featuredVideo.link} onChange={changeForm}
                            ></input>
                            <br/>
                            <button className='quick-action-button' onClick={() => updateFeaturedVideo()}>Save Changes</button>
                        </div>
                        {showErrorMessage()}
                    </div>
                </div>
                <div className='video-dashboard-lower-half-container'>
                    <p className='boxed-text'>All Videos</p>
                    <p>Add or edit any video</p>
                    <div className='video-search-and-new-video-container'>
                        <div>
                            <input 
                                className='search-input' type="text" placeholder='Enter title of video' 
                                value={currentQuery} onChange={changeQuery} data-testid='video-search-input' 
                                onKeyDown={handleEnter}
                            />
                            <button onClick={submitQuery} className='quick-action-button'>Search</button>
                        </div>
                        <Link to='/admin/newVideo'>
                            <button className='new-video-form'>New Video</button>
                        </Link>
                    </div>
                    <AdminListOfVideos videoQuery={videoQuery} user={user}/>
                </div>
            </div>
        </>
    );
}

export default AdminVideosPage;