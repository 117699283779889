import '../../css/UserAccountPage.css';

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage, { ErrorSection } from '../../components/Error';
import apiHelpers, { STATUS_CODES } from '../../helpers/apiHelpers';
import { validateEmail, validatePassword } from '../../helpers/fieldValidators';

import DeleteIcon from '@mui/icons-material/Delete';
import BottomBar from '../../components/BottomBar';
import Button from '../../components/Button';
import CheckBox from '../../components/Checkbox';
import InputField from '../../components/InputField';
import { LoadingSection } from '../../components/Loading';
import NavBar from '../../components/NavBar';
import { useUserContext } from '../../context/UserContext';

import { getDate } from '../../helpers/getDate';

const SuccessMessage = ({ message }) => {
    return (
        <div className="success-message">
            <p>{message}</p>
        </div>
    )
}

export const UserFields = ({ formData }) => {

    const { user } = useUserContext();

    const [messages, setMessages] = useState({
        firstName: '',
        lastName: '',
        email: '',
        zipCode: '',
        birthday: '',
        apiSuccess: '',
        apiError: '',
    });

    const validateFields = (firstName, lastName, email, zipCode, birthday) => {
        const errors = {
            firstName: '',
            lastName: '',
            email: '',
            zipCode: '',
            birthday: '',
        };

        // Check for empty fields
        if (firstName.length === 0) {
            errors.firstName = 'First name is required';
        }

        if (lastName.length === 0) {
            errors.lastName = 'Last name is required';
        }

        if (email.length === 0) {
            errors.email = 'Email is required';
        } else if (!validateEmail(email)) {
            errors.email = 'Please enter a valid email';
        }

        return errors;
    }

    const handleSave = async (e) => {
        e.preventDefault();

        // Check if anything has changed
        const firstName = document.getElementById('firstName').value;
        const lastName = document.getElementById('lastName').value;
        const email = document.getElementById('email').value;
        const zipCode = document.getElementById('zipCode').value;
        const birthday = document.getElementById('birthday').value;
        const emailUpdates = document.getElementById('emailUpdates').checked;

        const formErrors = validateFields(firstName, lastName, email, zipCode, birthday);
        const valid = Object.values(formErrors).every(error => error === '');

        setMessages({...formErrors, apiSuccess: '', apiError: ''});

        if (!valid) {
            return;
        }

        const updatedUser = {
            firstName,
            lastName,
            email,
            zipCode,
            birthday,
            emailUpdates,
        }

        const results = await apiHelpers.saveUserAccount(user, updatedUser)

        if (results.status !== STATUS_CODES.SUCCESS) {
            setMessages({
                firstName: '',
                lastName: '',
                email: '',
                zipCode: '',
                birthday: '',
                apiError: results.message,
                apiSuccess: '',
            })
        } else {
            setMessages({
                firstName: '',
                lastName: '',
                email: '',
                zipCode: '',
                birthday: '',
                apiError: '',
                apiSuccess: 'Account changes saved',
            })
        }

    }

    return (
        <div className="user-fields">
            <div className="user-fields__header">
                <h3>Account</h3>
                <ErrorMessage errorMessage={messages.apiError}/>
                <SuccessMessage message={messages.apiSuccess}/>
            </div>
            <form>
                <InputField
                    id="firstName"
                    data-testid="firstName"
                    type="text"
                    placeholder="First Name"
                    initialValue={formData.firstName}
                    error={messages.firstName}
                />
                <InputField
                    id="lastName"
                    data-testid="lastName"
                    type="text"
                    placeholder="Last Name"
                    initialValue={formData.lastName}
                    error={messages.lastName}
                />
                <InputField
                    id="email"
                    data-testid="email"
                    type="email"
                    placeholder="Email"
                    initialValue={formData.email}
                    error={messages.email}
                />
                <CheckBox
                    id="emailUpdates"
                    text="Subscribe to Nashville Insider newsletter"
                    initialValue={formData.emailUpdates}
                    onChange={() => {}}
                />
                <InputField
                    id="zipCode"
                    data-testid="zipCode"
                    type="text"
                    placeholder="Zip Code"
                    initialValue={formData.zipCode}
                    error={messages.zipCode}
                />
                <InputField
                    id="birthday"
                    data-testid="birthday"
                    type="date"
                    placeholder="Birthday"
                    initialValue={formData.birthday}
                    error={messages.birthday}
                />
                <Button
                    text='Save Changes'
                    onClick={handleSave}
                    type='primary'
                    isSubmit
                />
            </form>

        </div>

    )

}

export const ChangePassword = () => {

    const { user } = useUserContext();

    const [errors, setErrors] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        apiSuccess: '',
        apiError: '',
    });

    const validateFields = () => {

        const currentPassword = document.getElementById('currentPassword');
        const newPassword = document.getElementById('newPassword');
        const confirmPassword = document.getElementById('confirmPassword');

        const errors = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        };

        // Check for empty fields
        if (currentPassword.value.length === 0) {
            errors.currentPassword = 'Current password is required';
        }

        if (newPassword.value.length === 0) {
            errors.newPassword = 'New password is required';
        }

        if (confirmPassword.value.length === 0) {
            errors.confirmPassword = 'Confirm password is required';
        }

        // Check for matching passwords
        if (errors.newPassword === '' && errors.confirmPassword === '' && newPassword.value !== confirmPassword.value) {
            errors.confirmPassword = 'Passwords do not match';
            errors.newPassword = 'Passwords do not match';
        }

        // Check for valid password
        if (errors.newPassword === '' && !validatePassword(newPassword.value)) {
            errors.newPassword = 'Password does not meet requirements: 1 uppercase, 1 lowercase, 1 number, 1 special character, 8 characters';
        }

        return errors;
    }

    const handleSave = async (e) => {

        e.preventDefault();

        // Validate fields, if any errors, set errors and return early
        const formErrors = validateFields();

        const valid = Object.values(formErrors).every((error) => error === '');

        if (!valid) {
            setErrors({...errors, ...formErrors, api: ''});
            return;
        }

        const currentPassword = document.getElementById('currentPassword').value;
        const newPassword = document.getElementById('newPassword').value;
        const results = await apiHelpers.changePassword(user, currentPassword, newPassword);

        if (results.status !== STATUS_CODES.POST_SUCCESS) {
            setErrors({
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                apiSuccess: '',
                apiError: results.message,
            });
        } else {
            // Clear form
            document.getElementById('currentPassword').value = '';
            document.getElementById('newPassword').value = '';
            document.getElementById('confirmPassword').value = '';

            setErrors({
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                apiError: '',
                apiSuccess: 'Password changed successfully',
            });
        }

    }

    return (
        <div className="change-password">
            <div className="change-password__header">
                <h3>Change Password</h3>
                <ErrorMessage errorMessage={errors.apiError}/>
                <SuccessMessage message={errors.apiSuccess} />
            </div>
            <form>
                <InputField
                    id="currentPassword"
                    type="password"
                    placeholder="Current Password"
                    error={errors.currentPassword}
                />
                <InputField
                    id="newPassword"
                    type="password"
                    placeholder="New Password"
                    error={errors.newPassword}
                />
                <InputField
                    id="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    error={errors.confirmPassword}
                />
                <Button
                    text='Update Password'
                    onClick={handleSave}
                    type='primary'
                    isSubmit
                />
            </form>
        </div>
    )

}

export const DeleteAccount = () => {

    const { user, logout } = useUserContext();

    const [initialState, setInitialState] = useState(true);

    const [apiError, setApiError] = useState('');

    const handleDelete = async (e) => {

        e.preventDefault();

        const results = await apiHelpers.deleteUserAccount(user);

        if (results.status !== STATUS_CODES.SUCCESS) {
            setApiError('There was an error deleting your account');
        } else {
            logout()
        }

    }

    return (
        <div className="delete-account">
            <div className="delete-account__header">
                <h3>Delete Account</h3>
                <p>(This action is <strong>irreversible</strong>)</p>
                <ErrorMessage errorMessage={apiError}/>
            </div>
            <form id="delete-account-form">
                {initialState &&
                    <Button
                        text='Delete Account'
                        onClick={() => setInitialState(false)}
                        type='danger'
                        test_id='initial-delete-btn'
                    />
                }
                {!initialState &&
                <>
                    <Button
                        text='Cancel Deletion'
                        onClick={() => setInitialState(true)}
                        type='primary'
                    />
                    <Button
                        text='Confirm Deletion'
                        onClick={handleDelete}
                        type='danger'
                    />
                </>
                }
            </form>
        </div>
    )


}

export const LikedArtists = () => {

    const { user } = useUserContext();

    const [likedArtists, setLikedArtists] = useState(new Set());

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchData = async () => {
            const results = await apiHelpers.retrieveLikedArtists(user);

            if (results.status !== STATUS_CODES.SUCCESS) {
                setError('There was an error retrieving liked artists.');
                setLoading(false);
            } else {
                setLikedArtists(results.data.artists);
                setLoading(false);
            }

        }

        fetchData();

    }, [user]);


    const handleRemoveClick = async (e, artistId) => {

        e.preventDefault();

        const results = await apiHelpers.updateLikedArtist(user, artistId, false);

        if (results.status !== STATUS_CODES.SUCCESS) {
            // setError('There was an error removing these artists from your liked lists.');
            console.log('error removing artist from liked list');
        } else {
            setLikedArtists(likedArtists.filter(artist => artist.id !== artistId));
        }

    }

    const getContent = () => {

        if (loading) {
            return <LoadingSection />
        } else if (error) {
            return <ErrorSection errorMessage={error} />
        } else if (!likedArtists || likedArtists.length === 0) {
            return <p className="no-liked-message">You have not liked any artists yet.</p>
        } else {
            return (
                <div className="liked-items">
                    {likedArtists.map(artist => {
                        return (
                            <div className="liked-item" key={artist.id}>
                                <Link className="artist-info" to={`/artists/${artist.id}`}>
                                    <img src={artist.image} alt={artist.name} />
                                    <div className="artist-item__name">{artist.name}</div>
                                </Link>
                                <DeleteIcon
                                    onClick={(e) => handleRemoveClick(e, artist.id)}
                                    htmlColor='#EE4A4A'
                                    data-testid={`delete-icon-artist-${artist.id}`}
                                />
                            </div>
                        )
                    })}
                </div>

            )

        }

    }
    return (
        <div className="liked-artists" id="liked-artists">
            <div className="liked-section__header">
                <h3>Liked Artists</h3>
            </div>
            {getContent()}
        </div>
    )
}

export const LikedVideos = () => {

    const { user } = useUserContext();

    const [likedVideos, setLikedVideos] = useState(new Set());

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchData = async () => {

            const results = await apiHelpers.retrieveLikedVideos(user);

            if (results.status !== STATUS_CODES.SUCCESS) {
                setError('There was an error retrieving liked videos.');
                setLoading(false);
            } else {
                setLikedVideos(results.data.videos);
                setLoading(false);
            }

        }

        fetchData();

    }, [user]);

    const handleRemoveClick = async (e, videoId) => {

        e.preventDefault();

        const results = await apiHelpers.updateLikedVideo(user, videoId, false);

        if (results.status !== STATUS_CODES.SUCCESS) {
            console.log('error removing video from liked list')
        } else {
            setLikedVideos(likedVideos.filter(video => video.id !== videoId));
        }

    }

    const getContent = () => {

        if (loading) {
            return <LoadingSection />
        } else if (error) {
            return <ErrorSection errorMessage={error} />
        } else if (likedVideos.length === 0) {
            return <p className="no-liked-message">You have not liked any videos yet.</p>
        } else {
            return (
                <div className="liked-items">
                    {likedVideos.map(video => {
                        return (
                            <div className="liked-item" key={video.id}>
                                <Link className="video-info" to={`/videos/${video.id}`}>
                                    <img src={video.thumbnail} alt={video.title} />
                                    <div className="video-item__title">{video.title}</div>
                                </Link>
                                <DeleteIcon
                                    className="delete-icon"
                                    onClick={(e) => handleRemoveClick(e, video.id)}
                                    htmlColor='#EE4A4A'
                                    data-testid={`delete-icon-video-${video.id}`}
                                />
                            </div>
                        )
                    })}
                </div>

            )

        }

    }
    return (
        <div className="liked-videos" id="liked-videos">
            <div className="liked-section__header">
                <h3>Liked Videos</h3>
            </div>
            {getContent()}
        </div>
    )
}

const UserAccountPage = () => {

    const { user } = useUserContext();
    const navigate = useNavigate();


    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        emailUpdates: null,
        zipCode: '',
        birthday: '',
    });

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            const results = await apiHelpers.retrieveUserAccount(user);

            if (results.status !== STATUS_CODES.SUCCESS) {
                setError('There was an error retrieving account data. Please try again later.');
                setIsLoading(false)
            } else {
                const userData = results.data;

                const formattedBirthday = getDate(userData.birthday, 'YYYY-MM-DD');

                setUserData({
                    firstName: userData.first_name,
                    lastName: userData.last_name,
                    email: userData.email,
                    emailUpdates: userData.newsletter === 1,
                    zipCode: userData.zipcode,
                    birthday: formattedBirthday,
                })
                setIsLoading(false);
            }

        }

        if (user && !user.isAdmin) {
            fetchData();
        } else {
            navigate('/');
        }

    }, [user, navigate])

    const getContent = () => {
        if (isLoading) {
            return <LoadingSection />
        } else if (error) {
            return <ErrorSection errorMessage={error} />
        } else {

            const Settings = () => (
                <div className="settings">
                    <h2>Settings</h2>
                    <UserFields formData={userData}/>
                    <ChangePassword />
                    <DeleteAccount />
                </div>
            )

            const LikedContent = () => (
                <div className="liked-content">
                    <h2>Liked Content</h2>
                    <LikedArtists />
                    <LikedVideos />
                </div>
            )

            // Handle small period of time where user logged out / hasn't been fetched yet
            if (!user) {
                return <></>
            }

            return (
                <div className="account-page__panels">
                    <LikedContent />
                    <Settings />
                </div>
            )
        }
    }

    return (
        <div className="user-page account-page">
            <NavBar />
                <div className="account-page__content">
                    <div className="account-page__header">
                        <h1>Your Account</h1>
                    </div>
                    {getContent()}
                </div>
            <BottomBar />
        </div>
    )

}

export default UserAccountPage;
