import React from 'react'

import '../../css/admin-css/AdminHeadbar.css'

// React component that contains the headbar of the Admin pages
const AdminHeadbar = () => {
    return (
        <>
            <div className='headbar'>
                <img src={require('../../assets/small-logo.png')}/>
            </div>
        </>
    )
}

export default AdminHeadbar;