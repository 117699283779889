import React, { useState } from 'react';
import { useUserContext } from '../../context/UserContext';
import { Navigate } from 'react-router-dom'
import { validateEmail, validatePassword } from '../../helpers/fieldValidators';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminForms.css'

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';

const AdminNewAdmin = () => {
    const [confirmCompletion, setConfirmCompletion] = useState(false)
    const [sidebar, setSidebar] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null)
    const [fullName, setFullName] = useState('');
    const [confirmPssw, setConfirmPssw] = useState(''); 
    const [adminInfo, setAdminInfo] = useState({
        fName: '',
        lName: '',
        email: '',
        password: ''
    })

    const { user, logout } = useUserContext();

    // If user isn't logged in or is not an admin, redirect to admin home page
    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    const showSidebar = () => { setSidebar(!sidebar); }

    const showErrorMessage = () => {
        let green = (confirmCompletion ? 'green' : 'red')
        return (
            <div className={(errorMessage === null) ? 'admin-form-err' : `admin-form-err active ${green}`}>
                <p>{errorMessage}</p>
                <p 
                    onClick={() => {
                        setErrorMessage(null);
                        setConfirmCompletion(false);
                    }}
                    
                    className={(errorMessage === null) ? 'remove-error' : 'remove-error active'}
                >&#10006;</p> 
            </div>
        );
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        switch (name) {
            case 'fullName':
                setFullName(value);
                break;
            case 'email':
                setAdminInfo(prevState => ({
                    ...prevState,
                    email: value
                }));
                break;
            case 'password': 
                setAdminInfo(prevState => ({
                    ...prevState,
                    password: value
                }));
                break;
            case 'confirmPssw': 
                setConfirmPssw(value)
                break;
            default:
                break;
        }
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        async function submitAdmin(newAdmin) {
            const result = await adminApiHelpers.createNewAdmin(newAdmin, user);

            if (result.status !== STATUS_CODES.POST_SUCCESS) {
                setConfirmCompletion(false);
                setErrorMessage("Error creating new administrator account");
                return
            } else {
                setAdminInfo(prevState => ({
                    ...prevState,
                    email: '',
                    password: ''
                }))
                setFullName('')
                setConfirmPssw('')
                setConfirmCompletion(true);
                setErrorMessage('Account Successfully created')
            }
        }

        if (!validateEmail(adminInfo.email)) {
            setConfirmCompletion(false);
            setErrorMessage("Incorrect email format");
            return
        }

        if (!validatePassword(adminInfo.password)) {
            setConfirmCompletion(false);
            setErrorMessage("Password not in correct format");
            return
        }

        if (adminInfo.password !== confirmPssw) {
            setConfirmCompletion(false);
            setErrorMessage("Passwords do not match");
            return
        }

        let newName = fullName.split(" ");

        if (newName[0] === '') {
            setConfirmCompletion(false);
            setErrorMessage("No Name was given");
            return
        }

        const submit = {
            email: adminInfo.email,
            firstName: newName[0],
            lastName: adminInfo.lName,
            password: adminInfo.password
        }

        if (newName.length < 2) { 
            submit["lastName"] = 'N/A';
        } else {
            submit["lastName"] = newName[1];
        }

        submitAdmin(submit);
    }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Users" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                <h1>Create Administrator</h1>
                <br />
                <p className='boxed-text'>Credentials</p>
                <div className='new-admin-form-container'>
                    <div className='new-admin-form-input-container'>
                        <p>Name</p>
                        <input 
                            type='text' name='fullName' onKeyDown={handleEnter}
                            value={fullName} onChange={handleChange}
                        />
                    </div>
                    <div className='new-admin-form-input-container'>
                        <p>Email</p>
                        <input 
                            type='text' name='email'
                            value={adminInfo.email} onChange={handleChange}
                        />
                    </div>
                    <div className='new-admin-form-input-container'>
                        <p>Password</p>
                        <input 
                            type='password' name='password'
                            value={adminInfo.password} onChange={handleChange}
                        />
                    </div>
                    <div className='new-admin-form-input-container'>
                        <p>Confirm Password</p>
                        <input 
                            type='password' name='confirmPssw'
                            value={confirmPssw} onChange={handleChange}
                        />
                    </div>

                    <button onClick={handleSubmit} className='quick-action-button enlarge'>Create</button>
                    {showErrorMessage()}
                </div>
            </div>
        </>
    );
}

export default AdminNewAdmin;