import '../../css/UserHomePage.css'

import React, { useEffect, useState } from 'react';
import apiHelpers, { STATUS_CODES } from '../../helpers/apiHelpers';

import BottomBar from '../../components/BottomBar';
import { ErrorPage } from '../../components/Error';
import FeaturedVideo from '../../components/FeaturedVideo';
import { LoadingPage } from '../../components/Loading';
import NavBar from '../../components/NavBar';
import VideoCategory from '../../components/VideoCategory';
import app_store_logo from '../../assets/app-store-logo.svg';
import play_store_logo from '../../assets/play-store-logo.png';
import { useUserContext } from "../../context/UserContext";

const AppLinks = () => {

    return (
        <div className="app-links">
            <p className="app-links__text">
                Download the App
            </p>
            <a href="https://apps.apple.com/us/app/nashville-insider/id1540000000" target="_blank" rel="noreferrer">
                <img src={app_store_logo} alt="App Store Logo" />
            </a>
            <a href="https://play.google.com/store/games?hl=en_US&gl=US" target="_blank" rel="noreferrer">
                <img src={play_store_logo} alt="Play Store Logo" />
            </a>
        </div>
    )
}

const UserHomePage = () => {

    const { user } = useUserContext();

    const [featuredVideo, setFeaturedVideo] = useState(null);
    const [categories, setCategories] = useState([]);

    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        async function fetchData() {

            setIsLoading(true);

            const results = await apiHelpers.retrieveUserHomepage(user);

            if (results.status !== STATUS_CODES.SUCCESS) {
                setError('There was an error loading the homepage. Please refresh the page.')
                setIsLoading(false);
            } else {
                setFeaturedVideo(results.data.featured_video);
                setCategories(results.data.categories);
                setIsLoading(false);
            }
        }

        fetchData();

    }, [user])

    const Body = () => {

        if (error) {
            return <ErrorPage errorMessage={error} />
        } else if (isLoading) {
            return <LoadingPage />
        } else {
            return (
                <div className="user-page user-home">
                    <FeaturedVideo video={featuredVideo} />
                    <div className="user-home__categories">
                        {categories.map((category, index) => {
                            return (
                            <VideoCategory
                                key={index}
                                title={category.title}
                                videos={category.videos}
                                id={category.id}
                            />)
                        }
                        )}
                    </div>
                    <AppLinks />
                </div>
            )
        }
    }
    return (
        <>
            <NavBar />
                <Body />
            <BottomBar />
        </>

    );
}

export default UserHomePage;
