import axios from 'axios';

// API Endpoint helper functions. Currently, these all return static data
// but should be updated with actual axios calls when backend API endpoints are implemented

export const STATUS_CODES = {
    SUCCESS: 200,
    POST_SUCCESS: 201,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
}

/* Artists */
const retrieveArtistsByName = async (user, query) => {

    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    }

    const results = await axios.get(`/api/artists/phrase/${query}`, config)
    .then(response => {
        const { status, data, message } = response.data

        return {
            status,
            data,
            message,
        }
    })
    .catch(error => {
        const { status, message } = error.response.data

        return {
            status,
            message,
        }
    })

    return results
}

const retrieveArtistsByFirstLetter = async (user, letter) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    }

    const results = await axios.get(`/api/artists/letter/${letter}`, config)
    .then(response => {
        const { status, data, message } = response.data

        return {
            status,
            data,
            message,
        }
    })
    .catch(error => {
        const { status, message } = error.response.data

        return {
            status,
            message,
        }
    })

    return results
}

const retrieveArtistById = async (artistId, user) => {

    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    }

    const results = await axios.get(`/api/artists/${artistId}`, config)
    .then(response => {
        const { status, data, message } = response.data

        return {
            status,
            data,
            message,
        }
    })
    .catch(error => {
        const { status, message } = error.response.data

        return {
            status,
            message,
        }
    })

    return results
}

const retrieveAllArtistsPage = async () => {

    const results = await axios.get('/api/featured-artists')
    .then(response => {
        const { status, data, message } = response.data

        return {
            status,
            data,
            message,
        }
    })
    .catch(error => {
        const { status, message } = error.response.data

        return {
            status,
            message,
        }
    })

    return results
}

const retrieveLikedArtists = async (user) => {

    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    }

    const results = await axios.get(`/api/users/${user.id}/liked-artists`, config)
    .then(response => {
        const { status, data, message } = response.data

        return {
            status,
            data,
            message,
        }
    })
    .catch(error => {
        const { status, message } = error.response.data

        return {
            status,
            message,
        }
    })

    return results
}

const updateLikedArtist = async (user, artistId, targetValue) => {

    const removeOrAdd = targetValue ? 'add' : 'remove'

    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    }

    const results = await axios.put(`/api/users/liked/${removeOrAdd}-artist`, {
        userId: user.id.toString(),
        artistId: artistId.toString(),
    }, config)
    .then(response => {
        const { status, data, message } = response.data

        return {
            status,
            data,
            message,
        }
    })
    .catch(error => {
        const { status, message } = error.response.data

        return {
            status,
            message,
        }
    })

    return results
}

/* Categories */
const retrieveCategoryById = async (categoryId) => {

    const results = await axios.get('/api/tag/' + categoryId)
    .then(response => ({
        status: response.data.status,
        data: response.data.data,
        message: response.data.message,
    })).catch(err => {
        const { status, message } = err.response.data
        return {
            status,
            message,
        }
    })
    return results
}

/* Homepage - User */
const retrieveUserHomepage = async (user) => {

    let config = {}

    // If user is logged in, add token to request
    if (user && user.token) {

        config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }
    }

    const results = await axios.get('/api/home', config)
    .then(response => ({
        status: response.data.status,
        data: response.data.data,
        message: response.data.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return results
}


/* Search */
const searchVideosAndArtists = async (query) => {

    const results = await axios.get(`/api/search/${query}`)
    .then(response => ({
        status: response.status,
        data: response.data.data,
        message: response.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return results

}

/* User Account */
const retrieveUserAccount = async (user) => {

    const result = await axios.get('/api/users/' + user.id, { headers: { 'Authorization': `Bearer ${user.token}` } })
    .then(response => ({
        status: response.data.status,
        data: response.data.data,
        message: response.data.message,
    })).catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return result
}

const saveUserAccount = async (user, userFields) => {

    const config = {
        headers: {
            'Authorization': 'Bearer ' + user.token,
        }
    }

    const updatedFields = {
        email: userFields.email,
        firstName: userFields.firstName,
        lastName: userFields.lastName,
        zip_code: userFields.zipCode,
        id: user.id.toString(),
        birthday: userFields.birthday,
        newsletter: userFields.emailUpdates ? "1" : "0",

    }

    const result = await axios.put('/api/users/', updatedFields, config)
    .then(response => {
        const { status, message } = response.data

        return {
            status,
            message,
        }
    })
    .catch(error => {
        const { status, message } = error.response.data

        return {
            status,
            message,
        }
    })

    return result
}

const deleteUserAccount = async (user) => {

    // Set Authorization header
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token

    const result = await axios.delete('/api/users', { data: { id: user.id.toString() }})
    .then(res => ({
        status: res.status,
    })).catch(err => {
        const { status, message } = err.response.data

        return {
            status,
            message,
        }
    })

    // Clear Authorization header
    axios.defaults.headers.common['Authorization'] = ''

    return result
}

/* User Authentication */
const loginUser = async (loginFunc, email, password) => {

    const result = await loginFunc(email, password)

    return result

}

const registerUser = async (registerFunc, email, password, firstName, lastName, zipCode, birthday, subscribed) => {

    let result = await registerFunc(email, password, firstName, lastName, zipCode, birthday, subscribed)
    return result
}

const sendRecoveryCode = async (email) => {

    const result = await axios.post('/api/recovery/generate', { email })
    .then(response => ({
        status: response.data.status,
    })).catch(err => {
        const { status, message } = err.response.data
        return {
            status,
            message,
        }
    })
    return result
}

const validateRecoveryCode = async (email, recoveryCode) => {

    const result = await axios.post('/api/recovery/validate', { email, recoveryCode })
    .then(response => ({
        status: response.data.status,
    })).catch(err => {
        const { status, message } = err.response.data
        return {
            status,
            message,
        }
    })
    return result

}

const updateUserPassword = async (email, recoveryCode, newPassword) => {

    const result = await axios.post('/api/recovery/reset', { email, recoveryCode, password: newPassword })
    .then(response => ({
        status: response.data.status,
    })).catch(err => {
        const { status, message } = err.response.data
        return {
            status,
            message,
        }
    })
    return result

}

const changePassword = async (user, oldPassword, newPassword) => {

    const userId = user.id.toString()

    // Add JWT to header
    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    }

    const results = await axios.put('/api/users/password', { userId, currentPassword: oldPassword, newPassword }, config)
    .then(response => ({
        status: response.status,
        message: response.data.message,
    })).catch(err => {
        const { status, message } = err.response.data
        return {
            status,
            message,
        }
    })

    return results

}

/* Videos */
const retrieveLikedVideos = async (user) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    }

    const results = await axios.get(`/api/users/${user.id}/liked-videos`, config)
    .then(response => {
        const { status, data, message } = response.data

        return {
            status,
            data,
            message,
        }
    })
    .catch(error => {
        const { status, message } = error.response.data

        return {
            status,
            message,
        }
    })

    return results
}

const retrieveVideoById = async (video_id, user) => {

    // Get token
    const config = {}

    if (user && user.token) {
        config.headers = {
            'Authorization': 'Bearer ' + user.token,
        }
    }

    const results = await axios.get('/api/videos/' + video_id, config)
    .then(res => {
        const { status, message, data } = res.data

        return {
            status,
            message,
            data,
        }
    })
    .catch(error => ({
        status: error.response.status,
        message: error.response.message,
    }))

    return results
}

const updateLikedVideo = async (user, videoId, targetValue) => {

    const removeOrAdd = targetValue ? 'add' : 'remove'

    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    }

    const results = await axios.put(`/api/users/liked/${removeOrAdd}-video`, {
        userId: user.id.toString(),
        episodeId: videoId.toString(),
    }, config)
    .then(response => {
        const { status, data, message } = response.data

        return {
            status,
            data,
            message,
        }
    })
    .catch(error => {
        const { status, message } = error.response.data

        return {
            status,
            message,
        }
    })

    return results

}

const apiHelpers = {
    changePassword,
    deleteUserAccount,
    loginUser,
    registerUser,
    retrieveAllArtistsPage,
    retrieveArtistById,
    retrieveArtistsByFirstLetter,
    retrieveArtistsByName,
    retrieveCategoryById,
    retrieveLikedArtists,
    retrieveLikedVideos,
    retrieveUserAccount,
    retrieveUserHomepage,
    retrieveVideoById,
    saveUserAccount,
    searchVideosAndArtists,
    sendRecoveryCode,
    updateLikedArtist,
    updateLikedVideo,
    updateUserPassword,
    validateRecoveryCode,
}


export default apiHelpers;
