const getIFrame = (youtubeID, autoplay=false) => {
    return (
        <iframe
            title="Featured Video"
            id="featured-video-iframe"
            src={`https://www.youtube.com/embed/${youtubeID}?autoplay=${autoplay ? 1 : 0}`}
            frameBorder="0"
            allowFullScreen
            background="transparent"
        ></iframe>
    )
}

export default getIFrame
