import '../css/InputField.css';

import React from 'react';

const InputField = ({ id, type, label, placeholder, initialValue='', onBlur, error, children, compact, disabled, max }) => {

    return (
        <div className={`form-group ${compact ? 'compact' : ''}`} id={`${id}-group`}>
            <label htmlFor={id}>{label || placeholder}</label>
            <div className="input-group">
                <input id={id} className={`${error ? 'error-state': ''}`} defaultValue={initialValue} type={type} placeholder={placeholder} onBlur={onBlur} disabled={disabled} max={max} />
                {children}
                {error && <p className="error">{error}</p>}
            </div>
        </div>
    )
}

export default InputField;
