import './css/App.css';

import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AboutPage from './pages/users/AboutPage';
import AdminDashboard from './pages/admins/AdminDashboard';
import AdminLoginPage from './pages/admins/AdminLoginPage';
import ArtistsPage from './pages/users/ArtistsPage';
import CategoryPage from './pages/users/CategoryPage';
import Modal from 'react-modal'
import NotFoundPage from './pages/users/NotFoundPage';
import PrivacyPage from './pages/users/PrivacyPage';
import SearchResultsPage from './pages/users/SearchResultsPage';
import SingleArtistPage from './pages/users/SingleArtistPage';
import UserAccountPage from './pages/users/UserAccountPage';
import { UserContextProvider } from "./context/UserContext";
import UserHomePage from './pages/users/UserHomePage';
import VideoPage from './pages/users/VideoPage';
import AdminUsersDashboard from './pages/admins/AdminUsersDashboard';
import AdminOwnAccount from './pages/admins/AdminOwnAccount';
import AdminNewVideo from './pages/admins/AdminNewVideo';
import AdminEditVideo from './pages/admins/AdminEditVideo';
import AdminNewAdmin from './pages/admins/AdminNewAdmin';
import AdminVideosPage from './pages/admins/AdminVideosPage';
import AdminNewArtist from './pages/admins/AdminNewArtist';
import AdminEditArtist from './pages/admins/AdminEditArtist';
import AdminArtistPage from './pages/admins/AdminArtistPage';

const App = () => {

    // Set the modal app element
    useEffect(() => {
        Modal.setAppElement("#app")
    }, [])

    return (
        <Router>
            {/*
                UserContextProvider handles all user authentication (data, funcs, etc)
                and passes it down to all child components
            */}

            <UserContextProvider>
                <div id="app" className="app-content">
                    <Routes>
                        <Route path="/" element={<UserHomePage />} />
                        <Route path="/videos/:id" element={<VideoPage />} />
                        <Route path="/artists/:id" element={<SingleArtistPage />} />
                        <Route path="/artists" element={<ArtistsPage />} />
                        <Route path="/categories/:id" element={<CategoryPage />} />
                        <Route path="/search/:query" element={<SearchResultsPage />} />
                        <Route path="/account" element={<UserAccountPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/privacy" element={<PrivacyPage />} />
                        <Route path="/admin" element={<AdminLoginPage />} />
                        <Route path="/admin/dashboard" element={<AdminDashboard />} />
                        <Route path="/admin/userDashboard" element={<AdminUsersDashboard />} />
                        <Route path="/admin/ownAccount" element={<AdminOwnAccount />} />
                        <Route path='/admin/newVideo' element={<AdminNewVideo />} />
                        <Route path='/admin/editVideo/:id' element={<AdminEditVideo />} />
                        <Route path='/admin/newAdmin' element={<AdminNewAdmin />} />
                        <Route path='/admin/videos' element={<AdminVideosPage />} />
                        <Route path='/admin/artistDashboard' element={<AdminArtistPage />} />
                        <Route path='/admin/newArtist' element={<AdminNewArtist />} />
                        <Route path='/admin/editArtist/:id' element={<AdminEditArtist />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </div>
            </UserContextProvider>
        </Router>
    )

}

export default App;
