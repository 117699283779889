import '../css/BottomBar.css';

import { Link } from 'react-router-dom';
import React from 'react';
import facebook_logo from '../assets/facebook-logo.png';
import instagram_logo from '../assets/instagram-logo.png';
import twitter_logo from '../assets/twitter-logo.png';

const BottomBar = () => {

    if (window.location.pathname.includes('admin')) {
        return <></>
    }

    const screenWidth = window.innerWidth;

    return (
        <div className="bottom-bar">
            <div className="bottom-bar__left">
                <p>{screenWidth >= 850 ? '© Copyright - Nashville Insider' : '© Nashville Insider'}</p>
                <p>|</p>
                <Link to="/about">About</Link>
                <p>|</p>
                <Link to="/privacy">Privacy Policy</Link>
            </div>
            <div className="bottom-bar__right">
                <a href="https://www.facebook.com/nashvilleinsider/" target="_blank" rel="noreferrer">
                    <img src={facebook_logo} alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/nashvilleinsidertv/" target="_blank" rel="noreferrer">
                    <img src={instagram_logo} alt="Instagram" />
                </a>
                <a href="https://twitter.com/NashInsiderTV" target="_blank" rel="noreferrer">
                    <img src={twitter_logo} alt="Twitter" />
                </a>
            </div>
        </div>
    )
}

export default BottomBar
