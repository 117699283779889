import React from 'react';

import '../../css/admin-css/AdminForms.css'

const AdminTagShowcase = ({ tagList, deleteTag }) => {
    return (
        <>
            <div className='tags-list'>
                {tagList.map((item, index) => {
                    return (
                        <p key={index} className='boxed-text tag-remove' onClick={() => deleteTag(item)}>
                            {item.name}
                        </p>
                    )
                })}
            </div>
        </>
    );
}

export default AdminTagShowcase;