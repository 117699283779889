import React, { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import '../../css/admin-css/AdminUsersPage.css'

import AdminConfirmDelete from './AdminConfirmDelete';

const AdminListOfUsers = ({ userList, updateList, authorization }) => {
    const [numOfPages, setNumOfPages] = useState(Math.ceil(userList.length / 5));
    const [currentPage, setCurrentPage] = useState(1);
    const [currentList, setCurrentList] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [userToDelete, setUserToDelete] = useState({
        id: -1,
        name: '',
        email: '',
        login: ''
    });

    const pages = [];

    useEffect(() => {
        setNumOfPages(Math.ceil(userList.length / 5));
        setCurrentList(userList.slice(0, 5));
        setCurrentPage(1);
        for (let i = 0; i < numOfPages; i++) {
            pages.push("" + (i+1))
        }
    }, [userList])

    useEffect(() => {
        setCurrentList(userList.slice((currentPage - 1) * 5,currentPage * 5))
    }, [currentPage])

    const showConfirmDelete = (user) => { 
        setUserToDelete(user);
        setConfirmDelete(!confirmDelete); 
    }

    const doDelete = (user) => {
        updateList(user);
    }

    const changePage = (index) => {
        setCurrentPage(index + 1);
    }

    return (
        <>
            {/* <div className='pages-container'>
                {Array.from({ length: numOfPages }, (_, i) => {
                    return (
                        <button key={i} onClick={() => changePage(i)}>{i + 1}</button>
                    )
                })}
            </div> */}
            <ul className='list-of-users-container'>
                <li className='list-of-users-container-entry'>
                    <p className='bold'>Name</p>
                    <p className='bold'>Email Address</p>
                    <p className='bold'>Last Login</p>
                </li>
                {userList.map((item, index) => {
                    return (
                        <li key={index} className='list-of-users-container-entry'>
                            <span>{item.name}</span>
                            <div className='list-of-users-email-container'>
                                <span>{item.email}</span>
                                {/* {item.verified ? <CheckCircleIcon style={{
                                    color: 'green',
                                    fontSize: 'medium'
                                }}/> : <CancelIcon style={{
                                    color: 'red',
                                    borderRadius: '5px',
                                    fontSize: 'medium'
                                }}/>} */}
                            </div>
                            <span>{item.last_login}</span>
                            <div className='list-entry__actions-container'>
                                <div>
                                    <DeleteIcon 
                                        onClick={() => showConfirmDelete(item)} 
                                        style={{ color: 'red', cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
            <AdminConfirmDelete 
                trigger={confirmDelete} userInfo={userToDelete}
                showConfirmation={showConfirmDelete} doDelete={doDelete} authorization={authorization}
            />
        </>
    )
}

export default AdminListOfUsers;