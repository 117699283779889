import '../../css/CategoryPage.css';

import React, { useEffect, useState } from 'react';
import apiHelpers, { STATUS_CODES } from '../../helpers/apiHelpers';

import BottomBar from '../../components/BottomBar';
import { ErrorPage } from '../../components/Error';
import { LoadingPage } from '../../components/Loading';
import NavBar from '../../components/NavBar';
import VideoResults from '../../components/VideoResults';
import { Link, useParams } from 'react-router-dom';

export const CategoryPage = () => {

    const { id } = useParams()

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [name, setName] = useState(null)
    const [videos, setVideos] = useState([])
    const [artistId, setArtistId] = useState(null)

    useEffect(() => {

        const fetchData = async () => {
            setIsLoading(true)
            setError(null)

            const results = await apiHelpers.retrieveCategoryById(id);

            if (results.status !== STATUS_CODES.SUCCESS) {
                setError('There was an error retrieving the videos for this tag. Please try again later.')
                setIsLoading(false)
            } else {
                setName(results.data.name)
                setVideos(results.data.videos)
                setArtistId(results.data.artistId)
                setIsLoading(false)
            }
        }

        fetchData()

    }, [id])

    const Body = () => {
        if (isLoading) {
            return <LoadingPage />
        } else if (error) {
            return <ErrorPage errorMessage={error} />
        } else {
            return (
                <div className="user-page category-page">
                    <div className="category-page__top-bar">
                        <div className="top-bar__left">
                            <h1>Videos Tagged With '{name}'</h1>
                            <p id="video-count">{videos.length} {videos.length === 1 ? 'result' : 'results'}</p>
                        </div>
                        {artistId && <div className="top-bar__right">
                            <Link to={`/artists/${artistId}`}>
                                <p>View {name}</p>
                            </Link>
                        </div>}
                    </div>
                    <div className='category-page__videos'>
                        <VideoResults videos={videos} />
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <NavBar />
                <Body />
            <BottomBar />
        </>

    )

}

export default CategoryPage;
