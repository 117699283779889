import '../css/ShareModal.css';

import Button, { LinkButton } from './Button';

import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';
import Modal from 'react-modal';
import React from 'react';
import facebook_logo from '../assets/facebook-logo.png';
import twitter_logo from '../assets/twitter-logo.png';

export const ShareModalType = {
    VIDEO: 'Video',
    ARTIST: 'Artist',
}

const ShareOption = ({ onClick, href, text, icon }) => {

    return (
        <div className="share-option">
            <LinkButton
                url={href}
                text={text}
                onClick={onClick}
                className="share-option"
                icon={icon}
                type="no-style"
            />
        </div>

    )
}


const ShareModal = ({ isOpen, type, closeModal, name }) => {

    const shareUrl = window.location.href;

    const mailToLink = `mailto:?subject=Check%20Out%20${name}%20on%20Nashville%20Insider!&`+
                        `body=Visit%20this%20link%20to%20check%20out%20${name}%20on%20`+
                        `Nashville%20Insider!%20${shareUrl}`;

    const tweetText = `https://twitter.com/intent/tweet?text=Check%20Out%20${name}%20on%20Nashville%20Insider%21%20${shareUrl}`;

    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;

    const onCopyLink = () => {
        navigator.clipboard.writeText(shareUrl);
    }

    const ModalContent = () => {
        return (
            <div className="share-modal__content">
                <div className="content__header">
                    <h2>Share {type}</h2>
                    <Button
                        text={'Close'}
                        onClick={closeModal}
                        className="share-modal__close"
                        type="no-style"
                    />
                </div>
                <div className="content__options">
                    <div className="col">
                        <ShareOption
                            text="Copy Link"
                            icon={<LinkIcon htmlColor="inherit"/>}
                            onClick={onCopyLink}
                        />
                        <ShareOption
                            href={tweetText}
                            text="Twitter"
                            type="no-style"
                            icon={<img src={twitter_logo} alt="Twitter" />}
                        />
                    </div>
                    <div className="col">
                        <ShareOption
                            href={mailToLink}
                            text="Email"
                            icon={<EmailIcon htmlColor="inherit"/>}
                        />
                        <ShareOption
                            href={facebookUrl}
                            text="Facebook"
                            type="no-style"
                            icon={<img src={facebook_logo} alt="Facebook" />}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Share Modal"
            className="share-modal"
            overlayClassName="share-modal__overlay"
        >
            <ModalContent />
        </Modal>
    )

}

export default ShareModal;
