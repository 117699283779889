import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../context/UserContext';
import { Navigate, Link } from 'react-router-dom'

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';
import AdminListOfAdmins from '../../components/admin-components/AdminListOfAdmins';
import AdminListOfUsers from '../../components/admin-components/AdminListOfUsers';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminUsersPage.css'

import adminApiHelpers from '../../helpers/adminApiHelpers';
import { STATUS_CODES } from '../../helpers/apiHelpers';

//FIX Searchbar and Filter

// TODO: ADD THE LOGIN FILTER
const AdminUsersDashboard = () => {
    const [sidebar, setSidebar] = useState(true);
    const [emailFilter, setEmailFilter] = useState('all');
    const [loginFilter, setLoginFilter] = useState('all');
    const [filteredAdminList, setFilteredAdminList] = useState([]);
    const [filteredUserList, setFilteredUserList] = useState([]);
    const [adminList, setAdminList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [adminSearch, setAdminSearch] = useState('');
    const [rerender, setRerender] = useState(true);

    const { user, logout } = useUserContext();

    useEffect(() => {
        async function fetchData() {
            const admins = await adminApiHelpers.retrieveAllAdmins(user);
            
            if (admins.status !== STATUS_CODES.SUCCESS) {
                console.log("Error");
            } else {
                setAdminList(admins.data);
                setFilteredAdminList(admins.data);
            }

            const users = await adminApiHelpers.retrieveAllUsers(user);

            if (users.status !== STATUS_CODES.SUCCESS) {
                console.log("Error");
            } else {
                setUserList(users.data);
                setFilteredUserList(users.data);
            }
        }

        fetchData()
    }, [user, rerender])

    const showSidebar = () => { setSidebar(!sidebar); }

    const handleEmailFilters = (newFilter) => {
        if (emailFilter === newFilter) return emailFilter;

        setEmailFilter(newFilter);
    }

    const handleLoginFilter = (newFilter) => {
        if (loginFilter === newFilter) return loginFilter;

        setLoginFilter(newFilter);
    }

    const handleUserSearch = (e) => {
        e.preventDefault()

        setUserSearch(e.target.value);
    }

    const handleAdminSearch = (e) => {
        e.preventDefault()

        setAdminSearch(e.target.value);
    }

    // useEffect(() => {
    //     let newList = fakeUserList;
    //     let loweredCased = userSearch.toLowerCase();

    //     switch(emailFilter) {
    //         case 'all':
    //             setFilteredList(newList.filter(
    //                 user => {
    //                     return (user.name.toLowerCase().includes(loweredCased))
    //                 }
    //             ))
    //             break;
    //         case 'yes':
    //             setFilteredList(newList.filter(
    //                 user => {
    //                     return (user.name.toLowerCase().includes(loweredCased)
    //                     && user.verified === true)
    //                 }
    //             ))
    //             break;
    //         case 'no':
    //             setFilteredList(newList.filter(
    //                 user => {
    //                     return (user.name.toLowerCase().includes(loweredCased)
    //                     && user.verified === false)
    //                 }
    //             ))
    //             break;
    //         default:
    //             break;
    //     }

    // }, [emailFilter, userSearch])

    useEffect(() => {
        let loweredCased = userSearch.toLowerCase();
        let date = new Date();
        let newList = userList;

        function doSearch(list) {
            let newList = list.filter(user => {
                return (
                    user.name.toLowerCase().includes(loweredCased)
                )
            })

            return newList;
        }

        newList = doSearch(newList);

        switch(loginFilter) {
            case 'all': 
                setFilteredUserList(newList);
                break;
            case '30': 
                date.setDate(date.getDate() - 30)
                setFilteredUserList(newList.filter(
                    user => {
                        return (new Date(user.last_login) - date >= 0)
                    }
                ))
                break;
            case '60': 
                date.setDate(date.getDate() - 60)
                    setFilteredUserList(newList.filter(
                        user => {
                            return (new Date(user.last_login) - date >= 0)
                        }
                    ))
                break;
            case '90': 
                date.setDate(date.getDate() - 90)
                    setFilteredUserList(newList.filter(
                        user => {
                            return (new Date(user.last_login) - date >= 0)
                        }
                    ))
                break;
            default:
                break;
        }
        
    }, [userSearch, loginFilter])

    useEffect(() => {
        let loweredCased = adminSearch.toLowerCase();

        function doSearch(list) {
            setFilteredAdminList(list.filter(user => {
                return (
                    user.name.toLowerCase().includes(loweredCased)
                )
            }))
        }
        
        if (loweredCased === '') {
            setFilteredAdminList(adminList);
        } else {
            doSearch(adminList);
        }
    }, [adminSearch])

    const updateList = (user) => {
        setRerender(!rerender);
    }

    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Users" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                <h1>Users</h1>
                <p>Dashboard</p>
                <div className='user-page-admin-section'>
                    <div className='admin-titles-and-buttons'>
                        <div className='total-admin-container'>
                            <p className='boxed-text'>Administrators</p>
                            <p className='italics' id='total-text-font-size'>{adminList.length} Total Administrators</p>
                        </div>
                        <Link to='/admin/newAdmin'>
                            <button className='add-admin-button'>+ Add Admin</button>
                        </Link>
                    </div>
                    <div className='total-admin-container'>
                        <input 
                            className='search-input' type='text' value={adminSearch}
                            placeholder='Find an administrator' onChange={handleAdminSearch}>  
                        </input>
                        <p className='italics' id='total-text-font-size'>{filteredAdminList.length} Results</p>
                    </div>
                    <AdminListOfAdmins adminList={filteredAdminList}/>
                </div>

                <div className='user-page-user-section'>
                    <div className='user-page-user-section__user-list-container'>
                        <div className='user-page-user-section__header'>
                            <p className='boxed-text'>Users</p>
                            <p className='italics' id='total-text-font-size'>{userList.length} Total Users</p>
                        </div>
                        <div className='total-admin-container'>
                            <input 
                                className='search-input' type='text' value={userSearch}
                                placeholder='Find a user' onChange={handleUserSearch}>
                            </input>
                            <p className='italics' id='total-text-font-size'>{filteredUserList.length} Results</p>
                        </div>
                        <AdminListOfUsers userList={filteredUserList} updateList={updateList} authorization={user}/>
                    </div>
                    <div className='user-page-user-section__filter'>
                        <span className='boxed-text'>Filers</span>
                        <div>
                            {/* <div className='filter-list'>
                                <form>
                                    <p className='bold'>Email Verified</p>
                                    <label className='checkBox'>
                                        <input 
                                            type='radio' name='email-filter'
                                            onChange={() => handleEmailFilters('all')}>
                                        </input>
                                        All
                                    </label>
                                    <label className='checkBox'>
                                        <input 
                                            type='radio' name='email-filter'
                                            onChange={() => handleEmailFilters('yes')}>
                                        </input>
                                        Yes
                                    </label>
                                    <label className='checkBox'>
                                        <input
                                            type='radio' name='email-filter'
                                            onChange={() => handleEmailFilters('no')}>
                                        </input>
                                        No
                                    </label>
                                </form>
                            </div> */}
                            <div className='filter-list'>
                                <p className='bold'>Last Login</p>
                                <label className='checkBox'>
                                    <input 
                                        type='radio' name='login-filter'
                                        onChange={() => handleLoginFilter('all')}
                                    />
                                    All
                                </label>
                                <label className='checkBox'>
                                <input 
                                        type='radio' name='login-filter'
                                        onChange={() => handleLoginFilter('30')}
                                    />
                                    Last 30 days
                                </label>
                                <label className='checkBox'>
                                <input 
                                        type='radio' name='login-filter'
                                        onChange={() => handleLoginFilter('60')}
                                    />
                                    Last 60 days
                                </label>
                                <label className='checkBox'>
                                <input 
                                        type='radio' name='login-filter'
                                        onChange={() => handleLoginFilter('90')}
                                    />
                                    Last 90 days
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminUsersDashboard;