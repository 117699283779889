import React from 'react';
import { Link } from 'react-router-dom';

import '../../css/admin-css/AdminHomeLists.css'

const AdminHomeVideoList = ({ videoList }) => {
    return (
        <>
            <div className='order-list'>
                <ul className='video-or-artist-list'>
                    {videoList.map((item,index) => {
                        return (
                            <Link to={'/admin/editVideo/' + item.epid} 
                                state={{ videoID: item.epid}} key={index}
                                style={{ color: "black" ,textDecoration: "none"}}
                            >
                                <li className='video-entry' data-testid='home-video'>
                                    <h4>{index + 1}</h4>
                                    <img className='video-thumbnail' src={item.thumbnail} alt={item.title}/>
                                    <p>{item.title}</p>
                                </li>
                            </Link>
                        )
                    })}
                </ul>
            </div>
        </>
    );
}

export default AdminHomeVideoList;