import React, { useState } from 'react';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import { validatePassword } from '../../helpers/fieldValidators';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminUpdatePsswPopup.css'
import '../../css/admin-css/AdminForms.css'
import '../../css/admin-css/AdminSite.css'

const AdminUpdatePsswPopup = ({ trigger, showThePopup, admin, showConfirmMessage }) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const showErrorMessage = () => {
        return (
            <div className={(errorMessage === null) ? 'form-error' : 'form-error active center red'}>
                <p>{errorMessage}</p>
                <p 
                    onClick={() => setErrorMessage(null)} 
                    className={(errorMessage === null) ? 'remove-error' : 'remove-error active'}
                >&#10006;</p> 
            </div>
        );
    }

    const handleChange = (e) => {
        e.preventDefault()

        const {name, value } = e.target 

        switch(name) {
            case 'oldPassword': 
                setOldPassword(value);
                break;
            case 'newPassword':
                setNewPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
        }
    }

    const handleSubmit = () => {
        async function submit(newPassword) {
            const putRequest = {
                userId: "" + admin.id,
                currentPassword: oldPassword,
                newPassword: newPassword
            }

            const result = await adminApiHelpers.updatePassword(putRequest, admin);

            if (result.status !== STATUS_CODES.POST_SUCCESS) {
                setErrorMessage('Error trying to update password')
                return;
            } else {
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setErrorMessage(null);
                showConfirmMessage("Password successfully changed", "green");
                showThePopup();
            }
        }

        if (!validatePassword(newPassword)) {
            setErrorMessage('New passwords needs to follow proper format')
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage('New passwords do not match')
            return;
        }

        submit(newPassword);
    }

    return (trigger) ? (
        <>
            <div className='popup-background'>
                <div className='popup-container'>
                    <div className='remove-popup-container'>
                        <button className='remove-popup-button' onClick={showThePopup}>&#10006;</button>
                    </div>
                    <h1>Update Password</h1>
                    <div className='update-pssw-form'>
                        <div className='update-pssw-form__input-container'>
                            <p>Current Password</p>
                            <input
                                type='password' name='oldPassword'
                                value={oldPassword} onChange={handleChange}
                            />
                        </div>
                        <div className='update-pssw-form__input-container'>
                            <p>New Password</p>
                            <input
                                type='password' name='newPassword'
                                value={newPassword} onChange={handleChange}
                            />
                        </div>
                        <div className='update-pssw-form__input-container'>
                            <p>Confirm New Password</p>
                            <input
                                type='password' name='confirmPassword'
                                value={confirmPassword} onChange={handleChange}
                            />   
                        </div>
                    </div>
                    {showErrorMessage()}
                    <button onClick={() => handleSubmit()} className='popup-submit-button'>Update Password</button>
                </div>
            </div>
        </>
    ) : "";
}

export default AdminUpdatePsswPopup;