import React, { useState } from 'react';
import apiHelpers, { STATUS_CODES } from '../../helpers/apiHelpers';
import { validateEmail } from '../../helpers/fieldValidators';

import '../../css/admin-css/AdminForms.css'
import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminLogin.css'

import AdminChangeForgotPssw from './AdminChangeForgotPssw';

const AdminForgotPssw = ({ trigger, showThePopup }) => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState();
    const [formToShow, setFormToShow] = useState(0);
    const [showChangePssw, setShowChangePssw] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);


    const changeForm = () => { setFormToShow(!formToShow) };

    const tempShowError = (message) => {
        setTimeout(() => {
            setErrorMessage(null);
        }, 3000);
        setErrorMessage(message);
    }

    const showErrorMessage = () => {
        return (
            <div className={(errorMessage === null) ? 'form-error' : 'form-error active center red'}>
                <p>{errorMessage}</p>
                <p 
                    onClick={() => setErrorMessage(null)} 
                    className={(errorMessage === null) ? 'remove-error' : 'remove-error active'}
                >&#10006;</p> 
            </div>
        );
    }

    // Go back to login page
    const finishRecovery = () => {
        setShowChangePssw(false);
        changeForm();
        showThePopup();
    }

    // Force exit to login page
    const removeCode = () => {
        changeForm();
        showThePopup();
    }

    const changeEmail = (e) => {
        e.preventDefault()

        setEmail(e.target.value);
    }

    const changeCode = (e) => {
        e.preventDefault()

        setCode(e.target.value);
    }

    // Send recovery code
    const sendCode = () => {
        if (email === '') {
            tempShowError("Please enter an email to send our recovery code");
            return;
        } else if (!validateEmail(email)) {
            tempShowError("Invalid email address");
            return;
        }

        async function sendRecoveryCode(email) {
            const result = await apiHelpers.sendRecoveryCode(email)
            if (result.status !== STATUS_CODES.SUCCESS && result.status !== STATUS_CODES.POST_SUCCESS) {
                tempShowError("Error sending recovery code, check your email address");
            } else {
                setEmail('');
                changeForm();
            } 
        }

        sendRecoveryCode(email);
    }

    // Check if recovery code is correct
    const checkCode = () => {
        if (email === '' || code === undefined) {
            tempShowError("Please fill out all fields");
            return;
        } else if (!validateEmail(email)) {
            tempShowError("Invalid email address");
            return;
        }

        async function checkRecoveryCode(email, code) {
            const result = await apiHelpers.validateRecoveryCode(email, code)

            if (result.status !== STATUS_CODES.SUCCESS && result.status !== STATUS_CODES.POST_SUCCESS) {
                tempShowError("Error validating code, double check your email");
                return;
            } else {
                setShowChangePssw(true);
            }
        }

        checkRecoveryCode(email, code);
    }

    return (trigger) ? (
        <>
            {formToShow ? <>
                <div className='recovery-background'>
                    <div className='recovery-code-container'>
                        <div className='remove-popup-container'>
                            <button className='remove-popup-button' onClick={removeCode}>&#10006;</button>
                        </div>
                        <div className='forgot-pssw-popup'>
                            <h1>Forgot Password?</h1>
                            <p>Enter the recovery code sent to your email</p>
                            <div className='email-container'>
                                <p className='email-text'>Email</p>
                                <div className='login-data'>
                                    <input 
                                        type='text' placeholder='Enter email address'
                                        name='email' value={email} onChange={changeEmail}
                                    />
                                </div>
                            </div>
                            <div className='email-container'>
                                <p className='email-text'>Recovery Code</p>
                                <div className='login-data'>
                                    <input 
                                        type='number' placeholder='Enter recovery code'
                                        name='code' value={code} onChange={changeCode}
                                    />
                                </div>
                            </div>
                            <div className='recovery-section'>
                                <button onClick={() => checkCode()}>SUBMIT</button>
                            </div>
                        </div>
                        {showErrorMessage()}
                    </div>
                </div>
                <AdminChangeForgotPssw 
                    trigger={showChangePssw} finishRecovery={finishRecovery}
                    email={email} code={code}
                />
            </> : <>
                <div className='recovery-background'>
                    <div className='recovery-container'>
                        <div className='remove-popup-container'>
                            <button className='remove-popup-button' onClick={showThePopup}>&#10006;</button>
                        </div>
                        <div className='forgot-pssw-popup'>
                            <h1>Forgot Password?</h1>
                            <p>Enter your email address to receive a recovery code</p>
                            <div className='email-container'>
                                <p className='email-text'>Email</p>
                                <div className='login-data'>
                                    <input 
                                        type='text' placeholder='Enter email address'
                                        name='email' value={email} onChange={changeEmail}
                                    />
                                </div>
                            </div>
                            <div className='recovery-section'>
                                <p onClick={changeForm}>Already have a recovery code?</p>
                                <button onClick={() => sendCode()}>SEND CODE</button>
                            </div>
                        </div>
                        {showErrorMessage()}
                    </div>
                </div>
            </>}
        </>
    ) : "";
}

export default AdminForgotPssw;