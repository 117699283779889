import '../css/Checkbox.css';

import React, { useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const CheckBox = ({ className, initialValue, onChange, text, resourceId, children, id }) => {

    const [value, setValue] = useState(initialValue);

    const icon = value ? <CheckBoxIcon fontSize='inherit' htmlColor='inherit'/> : <CheckBoxOutlineBlankIcon fontSize='inherit' htmlColor='inherit'/>;

    const handleChange = () => {
        onChange(!value);
        setValue(!value);
    }

    return (
        <>
            <div id={id} className={`checkbox-group ${className || ''}`} onClick={handleChange} checked={value} resourceid={resourceId} >
                {icon}
                {children || <p className="checkbox-group-text">{text}</p>}
            </div>
        </>
    )

}

export default CheckBox;
