import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminArtistPage.css'

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ArtistConfirmDelete from './ArtistConfirmDelete';

const AdminArtistList = ({ artistQuery, user, refresh }) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [rerender, setRerender] = useState(true);
    const [noResult, setNoResult] = useState(true);
    const [artistToDelete, setArtistToDelete] = useState({
        id: '',
        name: ''
    });
    const [artistList, setArtistList] = useState([])

    // Find every artist that matches the query
    useEffect(() => {
        async function findArtists() {
            const result = await adminApiHelpers.retrieveArtists(artistQuery, user)
            
            if (result.status !== STATUS_CODES.SUCCESS) {
                console.log("Error retrieving videos");
            } else {
                if (result.data.artists < 1) setNoResult(true);
                else setNoResult(false);
                setArtistList(result.data.artists);
            }
        }

        if (artistQuery !== '') {
            findArtists();
        }

    }, [artistQuery, rerender])

    const showConfirmDelete = (artist) => { 
        setArtistToDelete(artist);
        setConfirmDelete(!confirmDelete); 
    }

    const doDelete = (artist) => {
        setRerender(!rerender);
        refresh();
    }

    return (
        <>
            <ul className='artist-list-container'>
                <li className='artist-info-container'>
                    <p className='bold'>Name</p>
                    <p className='bold'>Current Image</p>
                    <p className='bold'>Likes</p>
                    <p className='bold'>Actions</p>
                </li>
                { (artistQuery === '' || noResult) ? 
                <div>
                    <p className='empty-query'>No results based on search</p>
                </div> : <div>
                    {artistList.map((item, index) => {
                        return (
                            <li key={index} className='artist-info-container'>
                                <p>{item.name}</p>
                                <img src={item.img_path} className='featured-artist-image' alt={item.name}/>
                                <p>{item.like_count}</p>
                                <div style={{ display: 'flex', gap: '1rem' }}>
                                    <Link to={'/admin/editArtist/' + item.id} state={{ artistId: item.id}}>
                                        <EditIcon style={{ color: 'black' }}/>
                                    </Link> 
                                    <DeleteIcon 
                                        onClick={() => showConfirmDelete(item)} 
                                        style={{ color: 'red', cursor: 'pointer' }}
                                    />
                                </div>
                            </li>
                        )
                    })}
                </div>}
            </ul>
            <ArtistConfirmDelete 
                trigger={confirmDelete} artistInfo={artistToDelete} user={user}
                showConfirmation={showConfirmDelete} doDelete={doDelete}
            />
        </>
    );
}

export default AdminArtistList;