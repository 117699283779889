import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../context/UserContext';
import { Navigate } from 'react-router-dom';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminForms.css'

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';
import AdminArtistForm from '../../components/admin-components/AdminArtistForm';

const AdminNewArtist = () => {
    const [sidebar, setSidebar] = useState(true);
    const [artistInfo, setArtistInfo] = useState({
        id: -1,
        name: '',
        description: '',
        public: false,
        img_path: 'fake_img_path',
        featured: 0
    });

    const { user, logout } = useUserContext();

    // If user isn't logged in or is not an admin, redirect to admin home page
    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    const showSidebar = () => { setSidebar(!sidebar); }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Artists" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                <AdminArtistForm pageTitle={'New Artist'} artistToEdit={artistInfo} newArtist={true} user={user}/>
            </div>
        </>
    );
}

export default AdminNewArtist;