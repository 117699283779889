import '../css/UserAuthModal.css'

import Modal from 'react-modal'
import { UserForgotPasswordForm } from './UserForgotPasswordForm'
import { UserLoginForm } from './UserLoginForm'
import { UserRegisterForm } from './UserRegisterForm'
import { useState } from 'react'

const UserAuthStateEnum = {
    LOGIN: 'Login',
    REGISTER: 'Register',
    FORGOTPASSWORD: 'ForgotPassword',
};

const UserAuthModal = (props) => {

    const { handleClose, isOpen, test, subheading, loginRequired } = props

    const [formState, setFormState] = useState(UserAuthStateEnum.LOGIN)

    const updateFormState = (target) => {
        setFormState(target)
    }

    const closeForm = () => {

        // Close modal
        handleClose()

        // Reset form state after modal closes (after 300 ms)
        setTimeout(() => {
            document.getElementsByTagName('form')[0].reset()
            setFormState(UserAuthStateEnum.LOGIN)
        }, 300)

    }

    const getRenderedForm = () => {

        switch (formState) {
            case UserAuthStateEnum.REGISTER:
                return (
                    <UserRegisterForm
                        handleClose={closeForm}
                        updateFormState={updateFormState}
                        exitToHome={loginRequired}
                    />
                )

            case UserAuthStateEnum.FORGOTPASSWORD:
                return (
                    <UserForgotPasswordForm
                        handleClose={closeForm}
                        updateFormState={updateFormState}
                        exitToHome={loginRequired}
                    />
                )

            case UserAuthStateEnum.LOGIN:
            default:
                return (
                    <UserLoginForm
                        handleClose={closeForm}
                        updateFormState={updateFormState}
                        subheading={subheading}
                        exitToHome={loginRequired}
                    />
                )
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeForm}
            contentLabel="Authentication Modal"
            className='user-auth-modal'
            id='user-auth-modal'
            overlayClassName='user-auth-overlay'
            closeTimeoutMS={250}
            ariaHideApp={test ? false : true} // If testing, don't hide app
            shouldCloseOnOverlayClick={false} // Don't close when clicking outside modal
            shouldCloseOnEsc={false} // Don't close when pressing escape
        >
            {getRenderedForm()}
        </Modal>
    )

}

export { UserAuthModal, UserAuthStateEnum }
