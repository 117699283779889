import '../../css/ArtistsPage.css';

import { ErrorPage, ErrorSection } from '../../components/Error';
import React, { useEffect, useState } from 'react';
import apiHelpers, { STATUS_CODES } from '../../helpers/apiHelpers'

import BottomBar from '../../components/BottomBar';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { LoadingPage } from '../../components/Loading';
import NavBar from '../../components/NavBar';
import { useUserContext } from "../../context/UserContext"

const SearchType = {
    FirstLetter: 'FirstLetter',
    Full: 'Full',
  };

const letterList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S',
    'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const LetterBar = ({ callSearch, setQueryEntered, isActive }) => {

    const [selectedLetter, setSelectedLetter] = useState('');

    useEffect(() => {
        if (!isActive) {
            setSelectedLetter('');
        }
    }, [isActive]);

    const handleLetterClick = (letter) => {

        // Set query entered to toggle message if there are no results
        setQueryEntered(true);

        if (letter === selectedLetter) {
            return;
        }
        setSelectedLetter(letter);
        callSearch(letter);
    }

    return (
        <div className="letter-bar">
            {letterList.map(letter => {
                return (
                    <div className={`letter-bar__letter ${selectedLetter === letter ? 'is-selected' : null}`} key={letter} onClick={() => handleLetterClick(letter)} data-testid={`letter-${letter}`}>
                        {letter}
                    </div>
                )
            }
            )}
        </div>
    )
}

const ArtistSearchForm = ({ callSearch, setQueryEntered, isActive }) => {

        const [query, setQuery] = useState('');

        useEffect(() => {
            if (!isActive) {
                setQuery('');
            }
        }, [isActive]);

        const updateQuery = (e) => {
            setQuery(e.target.value)
        }

        const handleClick = (e) => {
            e.preventDefault();
            setQueryEntered(true);
            callSearch(query);
        }

        return (
            <form className="search-artists__search-form">
                <input type="text" placeholder="Search for an artist" data-testid="artistsearch-input" id="artist-input" onChange={updateQuery} defaultValue={query}/>
                <Button text="Search" onClick={handleClick} type="primary" disabled={query.length < 3} test_id="artistsearch-button" isSubmit/>
            </form>
        )
}


const FeaturedArtists = ({ artists }) => {

    if (!artists || artists.length === 0) {
        return null;
    }

    return (
        <div className="artists-page__featured-artists">
            <div className="featured-artists__header">
                <h2>Featured</h2>
            </div>
            <div className="featured-artists__list">
                {artists.map(artist => {
                    return (
                        <Link to={`/artists/${artist.id}`} className="featured-artists__list-item" key={artist.id}>
                            <div className="featured-artists__list-item-image">
                                <img src={artist.image} alt={artist.name} />
                            </div>
                            <p className="featured-artists__list-item-artist-name">{artist.name}</p>
                        </Link>
                    )
                }
                )}
            </div>
        </div>
    )
}

const ArtistSearchResult = ({ artist }) => {

    const { name, id } = artist

    return (
        <Link to={`/artists/${id}`} className="artist-search__search-result">
            <p className="search-result__name">{name}</p>
        </Link>
    )
}

const NoResultsMessage = ({queryEntered, error}) => {

    let message;

    if (error) {
        message = "There was an error loading results. Please try again later."
    } else if (queryEntered) {
        message = "No artists found."
    } else {
        message = "Search for an artist to see results."
    }

    return (
        <div className="no-results-message">
            <p>{message}</p>
        </div>
    )

}

const SearchArtists = () => {

    const [results, setResults] = useState(null);
    const [queryEntered, setQueryEntered] = useState(false);
    const [activeMethod, setActiveMethod] = useState(SearchType.FirstLetter);
    const [error, setError] = useState(null);

    const { user } = useUserContext();

    const callSearch = async (query, searchType) => {
        let results;

        if (searchType === SearchType.FirstLetter) {
            results = await apiHelpers.retrieveArtistsByFirstLetter(user, query);
            setActiveMethod(SearchType.FirstLetter);
            document.getElementById('artist-input').value = '';
        } else {
            results = await apiHelpers.retrieveArtistsByName(user, query);
            setActiveMethod(SearchType.Full);
        }

        if (results.status !== STATUS_CODES.SUCCESS) {
            setError(true)
            setResults(null);
        } else {
            setResults(results.data.artists);
            setError(false)
        }

    }

    return (
        <div className="artists-page__search-artists">
            <h2>Full Catalog</h2>
            <div className="search-artists__search-form">
                <ArtistSearchForm
                    callSearch={(query) => callSearch(query, SearchType.Full)}
                    setQueryEntered={setQueryEntered}
                    isActive={activeMethod === SearchType.Full}
                />
            </div>
            <div className="search-artists__search-bar">
                <LetterBar
                    callSearch={(query) => callSearch(query, SearchType.FirstLetter)}
                    setQueryEntered={setQueryEntered}
                    isActive={activeMethod === SearchType.FirstLetter}
                />
            </div>
            <div className="search-arists__search-results">
                {results && results.length > 0 ? (
                    results.map((item, index) => {
                        return (<ArtistSearchResult artist={item} key={index} />)})) : (<NoResultsMessage queryEntered={queryEntered} error={error}/>)}
            </div>
        </div>
    )

}

const ArtistsPage = () => {

    const { user } = useUserContext();

    const [featuredArtists, setFeaturedArtists] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            const results = await apiHelpers.retrieveAllArtistsPage();

            if (results.status !== STATUS_CODES.SUCCESS) {
                setError('There was an error retrieving the artists page. Please try again later.');
                setIsLoading(false);
            } else {
                setFeaturedArtists(results.data);
                setIsLoading(false)
            }
        }

        // Only fetch data if data isn't already loaded
        if (user && !featuredArtists) {
            fetchData();
        }

    }, [user, featuredArtists])

    const Body = () => {
        if (user && error) {
            return <ErrorPage errorMessage={error} />
        } else if (isLoading) {
            return <LoadingPage />
        } else if (user) {
            return (
                <div className="artists-page__body">
                    <div className="artists-page__header"><h1>All Artists</h1></div>
                    <FeaturedArtists artists={featuredArtists} />
                    <SearchArtists />
                </div>
            )
        } else {
            return (
                <div className="artists-page__body">
                    <div className="artists-page__header"><h1>All Artists</h1></div>
                    <ErrorSection errorMessage="You must be logged in to view this page." />
                </div>
            )
        }
    }

    const signUpSubheading = 'You must be signed in to view this content.'

    return (
        <>
            <NavBar loginRequired={!user} subheading={signUpSubheading}/>
                <div className="user-page artists-page">
                    <Body />
                </div>
            <BottomBar />
        </>
    )

}

export default ArtistsPage;
