import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../context/UserContext';
import { Navigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';
import AdminVideoForm from '../../components/admin-components/AdminVideoForm';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminForms.css'

const AdminEditVideo = () => {
    const params = useParams();
    const [sidebar, setSidebar] = useState(true);
    const [videoInfo, setVideoInfo] = useState(null);
    const [idOfVideo, setIdOfVideo] = useState(null);

    const { user, logout } = useUserContext();

    useEffect(() => {
        setIdOfVideo(params.id);
    }, [])

    useEffect(() => {
        async function fetchVideo(id) {
            const getVideoInfo = await adminApiHelpers.retrieveOneVideo(id, user);
            if (getVideoInfo.status !== STATUS_CODES.SUCCESS) {
                console.log("Error Loading Page");
            } else {
                if (getVideoInfo.data.data.video.epnum === null || getVideoInfo.data.data.video.epnum === -1) {
                    setVideoInfo({
                        id: getVideoInfo.data.data.video.id,
                        title: getVideoInfo.data.data.video.title,
                        description: getVideoInfo.data.data.video.description,
                        tags: getVideoInfo.data.data.video.tags,
                        youtube_id: getVideoInfo.data.data.video.youtube_id,
                        epnum: null,
                        youtube_url: getVideoInfo.data.data.video.youtube_url,
                        public_state: getVideoInfo.data.data.video.public_state
                    })
                } else {
                    setVideoInfo(getVideoInfo.data.data.video);
                }
            }
        }

        fetchVideo(idOfVideo);
    }, [idOfVideo])

    // If user isn't logged in or is not an admin, redirect to admin home page
    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    const showSidebar = () => { setSidebar(!sidebar); }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Videos" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                {videoInfo === null ? <div></div> : <AdminVideoForm pageTitle="Edit Video" videoToEdit={videoInfo} user={user}/>}
            </div>
        </>
    )
}

export default AdminEditVideo;