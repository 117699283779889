import React, { useState } from 'react';
import { useUserContext } from '../../context/UserContext';
import { Navigate } from 'react-router-dom'

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';
import AdminVideoForm from '../../components/admin-components/AdminVideoForm';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminForms.css'

// TODO: what is the episode number format
const AdminNewVideo = () => {
    const [sidebar, setSidebar] = useState(true);
    const [videoInfo, setVideoInfo] = useState({
        id: '-1',
        link: '',
        title: '',
        epnum: '',
        description: '',
        public: false,
        tags: []
    });

    const { user, logout } = useUserContext();

    // If user isn't logged in or is not an admin, redirect to admin home page
    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    const showSidebar = () => { setSidebar(!sidebar); }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Videos" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                <AdminVideoForm pageTitle="New Video" videoToEdit={videoInfo} user={user}/>
            </div>
        </>
    )
}

export default AdminNewVideo;