import '../css/VideoResults.css'

import { Link } from 'react-router-dom';
import React from 'react';

const VideoResult = ({video}) => {

    const { thumbnail, title, id } = video;

    return (
        <Link to={`/videos/${id}`} className="video-result">
            <img src={thumbnail} alt='video thumbnail'/>
            <div className="video-result__details">
                <p className="details__title">{title}</p>
            </div>
        </Link>
    )

}

const VideoResults = ({videos, maxVideos}) => {

    if (videos.length === 0) {
        return (
            <div className="video-results">
                <p>No videos found</p>
            </div>
        )
    }

    if (!maxVideos) {
        maxVideos = videos.length;
    }

    return (
        <div className="video-results">
            {videos &&
                videos.slice(0, Math.min(videos.length, maxVideos)).map(
                    (video, index) => <VideoResult key={index} video={video} />
                )
            }
        </div>
    )
}

export default VideoResults;
