import '../css/NavBar.css'

import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { UserAuthModal } from "./UserAuthModal";
import logo from '../assets/small-logo.png'
import { useEffect } from 'react';
import { useUserContext } from "../context/UserContext";

// Loads dark mode from local storage or detects if the user has a system dark mode preference and returns a boolean
const detectOrLoadInitialDarkMode = () => {
    if (localStorage.getItem("darkMode")) {
        return JSON.parse(localStorage.getItem("darkMode"))
    } else {
        const darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
        localStorage.setItem("darkMode", darkMode)
        return darkMode
    }
}

const NavBar = ({ loginRequired, subheading }) => {

    const { user, logout } = useUserContext();
    const navigate = useNavigate();

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [darkMode, setDarkMode] = useState(detectOrLoadInitialDarkMode())

    if (darkMode) {
        document.body.classList.add("dark-mode");
    } else {
        document.body.classList.remove("dark-mode");
    }

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    useEffect(() => {
        if (!user && loginRequired) {
            openModal()
        }
    }, [user, loginRequired])

    const callSearchResponsive = (e) => {
        e.preventDefault()

        const query = document.getElementById('search-input-responsive').value

        if (query) {
            navigate(`/search/${query}`)
        }

    }

    const callSearch = (e) => {
        e.preventDefault()

        const query = document.getElementById('search-input').value

        if (query) {
            navigate(`/search/${query}`)
        }
    }

    const AccountButton = () => {

        if (!user) {
            return (
                <div className="dropdown">
                    <button className="signin-btn" onClick={openModal}>Sign In</button>
                </div>
            )
        }

        const handleAccountClick = () => {
            navigate('/account')
        }

        return (
            <div className="dropdown">
                <button className="dropbtn">
                    <PersonIcon />
                    {user.firstName || 'Account'}
                </button>
                <div className="dropdown-content">
                    <button onClick={handleAccountClick}>Account</button>
                    <button onClick={logout}>Sign Out</button>
                </div>
            </div>
        )

    }

    const ResponsiveNav = () => {

        const [showMenu, setShowMenu] = useState(false)

        const handleMenuClick = () => {
            setShowMenu(!showMenu)
        }

        return (
            <nav className="responsive-nav">
                <div className="responsive-nav__top-bar">
                    <div className="responsive-nav-logo">
                        <Link to="/">
                            <img src={logo} alt="logo" id="logo"/>
                        </Link>
                    </div>
                    <DarkModeToggle />
                    <div className="responsive-nav-menu">
                        <MenuIcon onClick={handleMenuClick} />
                    </div>
                </div>
                <div className={`responsive-nav-links ${showMenu ? 'show' : ''}`}>
                    <Link to="/" className="nav-link">Home</Link>
                    <a href="https://nashvilleinsider.tv/" className="nav-link" target="_blank" rel="noreferrer" >Main Site</a>
                    <Link to="/artists" className="nav-link">Artists</Link>
                    <form className="search-container">
                        <input id="search-input-responsive" type="text" placeholder="Enter a search term"/>
                        <button type="submit" className="nav-link" onClick={callSearchResponsive}>Search</button>
                    </form>
                    <AccountButton />
                </div>
            </nav>
        )
    }

    const DarkModeToggle = () => {

        const handleDarkModeToggle = () => {
            setDarkMode(!darkMode)
            localStorage.setItem("darkMode", !darkMode)

            if (darkMode) {
                document.body.classList.remove('dark-mode')
            } else {
                document.body.classList.add('dark-mode')
            }
        }

        return (
            <div className="dark-mode-toggle" onClick={handleDarkModeToggle}>
                {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
            </div>
        )

    }

    return (
        <>
            <nav className="navbar">
                <img src={logo} alt="Nashville insider logo" id="navbar-logo"/>
                <a href="https://nashvilleinsider.tv/" className="nav-link" target="_blank" rel="noreferrer" >Main Site</a>
                <Link to="/" className="nav-link">Home</Link>
                <Link to="/artists" className="nav-link">Artists</Link>
                <form className="search-container">
                    <input id="search-input" type="text" placeholder="Enter topics, artists, etc..."/>
                    <button type="submit" className="nav-link" onClick={callSearch}>Search</button>
                </form>
                <DarkModeToggle />
                <AccountButton />
                <UserAuthModal
                    loginRequired={loginRequired}
                    handleClose={closeModal}
                    isOpen={modalIsOpen}
                    subheading={subheading}
                />
            </nav>
            <ResponsiveNav />
        </>

    )
}

export default NavBar;
