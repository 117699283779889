import React, { useState } from 'react';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminForms.css'
import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminConfirmDelete.css'


const ArtistConfirmDelete = ({ trigger, showConfirmation, artistInfo, doDelete, user }) => {
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [colorMessage, setColorMessage] = useState('red');

    const deleteArtist = (artist) => {
        async function removeArtist(artist) {
            const query = "" + artist.id;
            const result = await adminApiHelpers.deleteArtist(query, user);

            if (result.status !== STATUS_CODES.SUCCESS) {
                setMessage("Error deleting artist");
                setColorMessage('red');
                temporaryShow();
            } else {
                setMessage("Artist Deleted Successfully");
                setColorMessage('green');
                temporaryShow();
                setTimeout(() => {
                    showConfirmation();
                    doDelete(artist);
                }, 3000);
            }
        }

        removeArtist(artist);
    }

    const temporaryShow = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);
    }

    const showDeleteResult = () => {
        return showMessage ? (
            <div className={`message-box ${colorMessage}`}>
                <p>{message}</p>
            </div>
        ) : "";
    }
    
    return (trigger) ? (
        <>
            <div className='popup-background'>
                <div className='popup-container-for-delete-artist'>
                    <div className='remove-popup-container'>
                        <button className='remove-popup-button' onClick={showConfirmation}>&#10006;</button>
                    </div>
                    <h1>Confirm Artist Deletion</h1>
                    <div className='user-info-container'>
                        <p className='user-info'>Artist: {artistInfo.name}</p>
                    </div>
                    <div className='confirm-delete-message'>
                        <p>Are you sure you want to delete this artist?</p>
                        <p>This process cannot be undone</p>
                    </div>
                    <div className='button-section'>
                        <button onClick={() => deleteArtist(artistInfo)} className='quick-action-button increase-size'>Delete Artist</button>
                    </div>
                    {showDeleteResult()}
                </div>
            </div>
        </>
    ) : "";
}

export default ArtistConfirmDelete;