import BottomBar from '../../components/BottomBar';
import { ErrorPage } from '../../components/Error';
import NavBar from '../../components/NavBar';
import React from 'react';

const NotFoundPage = () => {
    return (
        <>
            <NavBar />
            <ErrorPage errorMessage="This page could not be found." />
            <BottomBar />
        </>

    )
}

export default NotFoundPage;
