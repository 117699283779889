import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import adminApiHelpers from '../../helpers/adminApiHelpers';
import { STATUS_CODES } from '../../helpers/apiHelpers';

import '../../css/admin-css/AdminAddLayout.css'
import '../../css/admin-css/AdminForms.css'
import '../../css/admin-css/AdminSite.css'

import { fakeTags } from '../../helpers/fake_admin_data';

const AdminAddLayout = ({ trigger, showThePopup, addLayout, user }) => {
    const [newTag, setNewTag] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [tagList, setTagList] = useState(fakeTags);

    useEffect(() => {
        async function fetchTags() {
            const allTags = await adminApiHelpers.retrieveTags(user);

            if (allTags.status !== STATUS_CODES.SUCCESS) {
                console.log("Error retrieving tags");
            } else {
                setTagList(allTags.data.tags);
            }
        }

        fetchTags();
    }, [])

    const showErrorMessage = () => {
        return (
            <div className={(errorMessage === null) ? 'form-error' : 'form-error active center'}>
                <p>{errorMessage}</p>
                <p 
                    onClick={() => setErrorMessage(null)} 
                    className={(errorMessage === null) ? 'remove-error' : 'remove-error active'}
                >&#10006;</p> 
            </div>
        );
    }

    const handleEnter = () => {
        if (newTag === null) {
            setErrorMessage("Invalid Tag Given");
            return
        }

        let newestTag = newTag
        newestTag.id = '' + newestTag.id
        addLayout(newTag);
        showThePopup()
    }

    return (trigger) ? (
        <>
            <div className='popup-background'>
                <div className='popup-container layout'>
                    <div className='remove-popup-container'>
                        <button className='remove-popup-button' onClick={showThePopup}>&#10006;</button>
                    </div>
                    <div className='layout-form-container'>
                        <h1>Add a tag to your layout</h1>
                        <p className='italic-text'> Note: you can only add an existing tag in the user page layout.</p>
                        <p className='italic-text'>If you don't see the tag you want featured first add it to an existing video</p>
                        <div className='autocomplete-container'>
                            <Autocomplete 
                                id='tag-input-field'
                                clearOnEscape
                                onChange={(e, newValue) => {
                                    setNewTag(newValue)
                                }}
                                options={tagList}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField 
                                        variant="outlined"
                                        sx={{
                                            "& .MuiOutlinedInput-root.Mui-focused": {
                                            "& > fieldset": {
                                                borderColor: "gray"
                                            }
                                            },
                                            "& label.Mui-focused" : {
                                                color : 'gray'
                                            },
                                            width: 340,
                                            marginTop: 1
                                        }}
                                        {...params} label="Start typing and press enter to add a tag" 
                                    />
                                )}
                            />
                        </div>
                        <button className='quick-action-button' onClick={() => handleEnter()}>Add Tag to Layout</button>
                        {showErrorMessage()}
                    </div>
                </div>
            </div>
        </>
    ) : "";
}

export default AdminAddLayout;