import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Autocomplete, TextField } from '@mui/material';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import AdminTagShowcase from './AdminTagShowcase';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminForms.css'

const AdminVideoForm = ({ pageTitle, videoToEdit, user }) => {

    const [tagList, setTagList] = useState([]);
    const [newTag, setNewTag] = useState(null);
    const [videoInfo, setVideoInfo] = useState(videoToEdit);
    const [currentTags, setCurrentTags] = useState(videoToEdit.tags);
    const [publicStatus, setPublicStatus] = useState(videoToEdit.public_state);

    const [tagsToCreate, setTagsToCreate] = useState([]);
    const [tagsToUpdate, setTagsToUpdate] = useState([]);
    const [tagsToDelete, setTagsToDelete] = useState([]);

    const [errorMessage, setErrorMessage] = useState(null);
    const [errorColor, setErrrorColor] = useState("red");

    useEffect(() => {
        async function fetchTags() {
            const allTags = await adminApiHelpers.retrieveTags(user);

            if (allTags.status !== STATUS_CODES.SUCCESS) {
                console.log("Error retrieving tags");
            } else {
                setTagList(allTags.data.tags);
            }
        }

        fetchTags();
    }, [])

    const changeForm = (e) => {
        e.preventDefault()

        const {name, value } = e.target

        switch (name) {
            case 'title':
                setVideoInfo(prevState => ({
                    ...prevState,
                    title: value
                }));
                break;
            case 'link':
                setVideoInfo(prevState => ({
                    ...prevState,
                    link: value
                }));
                break;
            case 'epNum':
            setVideoInfo(prevState => ({
                ...prevState,
                epnum: value
            }));
            break;
            case 'description':
            setVideoInfo(prevState => ({
                ...prevState,
                description: value
            }));
            break;
            default:
                break;
        }
    }

    const changePublic = (publicStatus) => {
        setPublicStatus(publicStatus);
    }

    const changeTag = (e) => {
        setNewTag({
            id: -1,
            name: e.target.value
        })
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()

            if (newTag === null) {
                return;
            }
            if (newTag.id === -1) {
                if (tagList.some(element => element.name === newTag.name)) {
                    let duplicate = tagList.find(obj => {
                        return obj.name === newTag.name;
                    })
                    setTagsToUpdate(prevState => [...prevState, duplicate])
                    setTagsToDelete(
                        tagsToDelete.filter(prevState => {
                            return prevState !== duplicate
                        })
                    )
                } else {
                    setTagsToCreate(prevState => [...prevState, newTag.name])
                }
            } else {
                setTagsToUpdate(prevState => [...prevState, newTag])
                setTagsToDelete(
                    tagsToDelete.filter(prevState => {
                        return prevState !== newTag
                    })
                )
            }

            setNewTag(null);

            setCurrentTags([...currentTags, newTag]);
        }
    }

    const deleteTag = (deleteTag) => {
        let newTags = currentTags.filter(tag => tag.name !== deleteTag.name)
        setCurrentTags(newTags);
        if (deleteTag.id !== -1) {
            setTagsToDelete(prevState => [...prevState, deleteTag])
            setTagsToUpdate(tagsToUpdate.filter(prevState => prevState.name !== deleteTag.name))
        }
        else {
            setTagsToCreate(tagsToCreate.filter(prevState => prevState !== deleteTag.name))
        }
    }

    const showErrorMessage = () => {
        return (
            <div className={(errorMessage === null) ? 'form-error' : `form-error active ${errorColor}`}>
                <p>{errorMessage}</p>
                <p 
                    onClick={() => {
                        setErrorMessage(null);
                        setErrrorColor("red");
                    }}
                    className={(errorMessage === null) ? 'remove-error' : 'remove-error active'}
                >&#10006;</p>
            </div>
        );
    }

    const submitForm = (e) => {
        e.preventDefault();

        if (videoInfo.title === '' || videoInfo.link === '' ||
            videoInfo.epnum === '' || videoInfo.description === '') {
            setErrorMessage("Please fill all necessary information");
            return;
        }

        async function uploadNewTags(tags) {
            const json = {
                id: videoInfo.id.toString(),
                name: tags
            }

            const result = await adminApiHelpers.addTags(json, user)

            if (result.status !== STATUS_CODES.POST_SUCCESS) {
                console.log("error uploading new tags")
            } else {
                console.log("Tags uploaded");
            }
        }

        async function updateTags(tags) {
            let tagIds = []
            for (var x in tags) {
                tagIds.push(tags[x].id)
            }

            const json = {
                epid: videoInfo.id.toString(),
                tagid: tagIds
            }

            const result = await adminApiHelpers.updateTags(json, user)

            if (result.status !== STATUS_CODES.SUCCESS) {
                console.log("error uploading")
            } else {
                console.log("Tags uploaded");
            }
        }

        async function deleteTags(tags) {
            let tagIds = []
            for (var x in tags) {
                tagIds.push(tags[x].id)
            }

            const json = {
                epid: videoInfo.id.toString(),
                tagid: tagIds
            }

            const result = await adminApiHelpers.deleteTags(json, user)

            if (result.status !== STATUS_CODES.SUCCESS) {
                console.log("error uploading")
            } else {
                console.log("Tags deleted");
            }
        }

        async function uploadVideo() {
            let statusToSend = publicStatus ? "1" : "0";

            const videoUploaded = await adminApiHelpers.uploadVideo(videoInfo, statusToSend, user);
            
            if (videoUploaded.status !== STATUS_CODES.SUCCESS) {
                setErrorMessage("Error updating video")
            } else {
                setErrrorColor("green");
                setErrorMessage("Video updated successfully");
            }
        }

        uploadVideo();
        if (tagsToCreate.length > 0) uploadNewTags(tagsToCreate);
        if (tagsToUpdate.length > 0) updateTags(tagsToUpdate);
        if (tagsToDelete.length > 0) deleteTags(tagsToDelete);
    }

    const importVideo = () => {
        async function retrieveVideo() {
            const result = await adminApiHelpers.getVideoImport(videoInfo.link, user)

            if (result.status === STATUS_CODES.FORBIDDEN) {
                setErrorMessage("Authentification Error.\nLogin to your YouTube account and return here once you are logged in")
            } else if (result.status === 409) {
                setErrorMessage("This video has already been imported")
            } else if (result.status !== STATUS_CODES.POST_SUCCESS) {
                setErrorMessage("Error getting video");
            } else {
                setErrrorColor("green");
                setErrorMessage("Video imported successfully");
                setVideoInfo({
                    id: result.data.data.epid,
                    title: videoInfo.title,
                    epnum: videoInfo.epnum,
                    description: videoInfo.description,
                    public_state: result.data.public_state
                });
            }
        }

        retrieveVideo();
    }

    const handleKey = (e) => {
        if (e.key === "Enter") {
            importVideo();
        }
    }

    return (
        <>
            <h1>{pageTitle}</h1>
            <div className='new-or-edit-video-form-container'>
                <div className='video-info-and-inputs'>
                    <div className='find-video-container'>
                        <h3>YouTube Link</h3>
                        <div className='youtube-link-input'>
                            <input
                                placeholder='Enter YouTube Video Link' className='form-text-input'
                                type='text' name='link' onKeyDown={handleKey}
                                value={videoInfo.youtube_url} onChange={changeForm}
                            />
                            <button className='quick-action-button' onClick={() => importVideo()}>Find Video</button>
                        </div>
                    </div>
                    <div className='find-video-container'>
                        <h3>Title</h3>
                        <input
                            placeholder='Enter video title' className='form-text-input'
                            type='text' name='title'
                            value={videoInfo.title} onChange={changeForm}
                        />
                    </div>
                    <div className='find-video-container'>
                        <h3>Episode Number</h3>
                        <input
                            placeholder='Enter episode number' className='form-text-input'
                            type='text' name='epNum'
                            value={videoInfo.epnum === null ? "" : videoInfo.epnum} onChange={changeForm}
                        />
                    </div>
                    <div className='find-video-container'>
                        <h3>Description</h3>
                        <textarea
                            placeholder='Enter video description' className='textarea-tag-input'
                            type='text' name='description'
                            value={videoInfo.description} onChange={changeForm}
                        />
                    </div>
                    {showErrorMessage()}
                    <div className='gap-for-buttons'>
                        {/* <button className='quick-action-button bigger-font' disabled>Preview</button> */}
                        <Link to='/admin/newVideo' onClick={() => window.location.reload()}>
                            <button className='quick-action-button bigger-font'>Discard</button>
                        </Link>
                        <button className='quick-action-button bigger-font' onClick={submitForm}>Publish</button>
                    </div>

                </div>
                <div>
                    <div className='privacy-settings-container'>
                        <p className='boxed-text bold'>Privacy Settings</p>
                        <div>
                            <label className='privacy-setting-container'>
                                <input 
                                    type='radio' name='privacy-setting' checked={publicStatus}
                                    onChange={() => changePublic(true)}
                                />
                                <div>
                                    <p className='bold'>Public</p>
                                    <p className='smaller-font'>Anyone on Nashville Insider can watch this video</p>
                                </div>
                            </label>
                            <label className='privacy-setting-container'>
                                <input 
                                    type='radio' name='privacy-setting' checked={!publicStatus}
                                    onChange={() => changePublic(false)}
                                />
                                <div>
                                    <p className='bold'>Private</p>
                                    <p className='smaller-font'>Only you and other admins can see this video</p>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div>
                        <p className='boxed-text bold'>Tags</p>
                        <Autocomplete
                            id='tag-input-field'
                            clearOnEscape
                            onKeyDown={handleEnter}
                            onChange={(e, newValue) => {
                                setNewTag(newValue);
                            }}
                            options={tagList}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    onChange={changeTag}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                        "& > fieldset": {
                                            borderColor: "gray"
                                        }
                                        },
                                        "& label.Mui-focused" : {
                                            color : 'gray'
                                        },
                                        width: 340,
                                        marginTop: 1
                                    }}
                                    {...params} label="Start typing and press enter to add a tag"
                                />
                            )}
                        />
                    </div>
                    <AdminTagShowcase tagList={currentTags} deleteTag={deleteTag}/>
                </div>
            </div>
        </>
    );
}


export default AdminVideoForm;
