import '../../css/VideoPage.css';

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LikeButton, ShareButton } from '../../components/Button';
import ShareModal, { ShareModalType } from '../../components/ShareModal';
import apiHelpers, { STATUS_CODES } from '../../helpers/apiHelpers';

import BottomBar from '../../components/BottomBar';
import { ErrorPage } from '../../components/Error';
import { LoadingPage } from '../../components/Loading';
import NavBar from '../../components/NavBar';
import VideoTags from '../../components/VideoTags';
import { useUserContext } from "../../context/UserContext";
import getIFrame from '../../helpers/getIFrame';

const SuggestedVideo = ({ video }) => {

    const { id, thumbnail, title } = video || {};

    return (
        <Link to={`/videos/${id}`} className="suggested-video">
            <img src={thumbnail} alt={title} className="suggested-video__image"/>
            <div className="suggested-video__video-details">
                <p className="suggested-video__title" title={title}>{title}</p>
            </div>
        </Link>
    )
}

const SuggestedVideos = ({ videos }) => {

    return (
        <div className="body__suggested-videos">
            <div className="suggested-videos__header">
                <h2>Suggested Videos</h2>
            </div>
            {videos && (
                <div className="suggested-videos__videos">
                    {videos.map((video, index) => <SuggestedVideo key={index} video={video} />)}
                </div>
            )}
        </div>
    )
}

const MainVideo = ({ video, user, isLiked }) => {

    const [likeStatus, setLikeStatus] = useState(isLiked)
    const [shareModalOpen, setShareModalOpen] = useState(false)

    const handleLikeButtonClick = async () => {
        const result = await apiHelpers.updateLikedVideo(user, video.id, !likeStatus)

        if (result.status !== STATUS_CODES.SUCCESS) {
            console.log(`There was an error ${isLiked ? 'unliking' : 'liking'} this video`);
        } else {
            setLikeStatus(!likeStatus)
        }
    }

    const signedIn = user !== null;

    return (
        <div className="body__main-video">
            <div className="main-video__video">
                {video && getIFrame(video.youtube_id, true)}
            </div>
            <div className="main-video__details">
            <h1 id="main-video__details__title">{video.title}</h1>
            <div className="main-video__details__row-interaction">
                <LikeButton
                    disabled={!signedIn}
                    isLiked={likeStatus}
                    onClick={handleLikeButtonClick}
                    text={signedIn ? "Like" : "Sign in to Like"}
                />
                <ShareButton onClick={() => setShareModalOpen(true)}/>
            </div>
            <p className="details__description">{video.description}</p>
            {video.tags && video.tags.length > 0 ? <VideoTags tags={video.tags} /> : null}
            </div>
            <ShareModal
                isOpen={shareModalOpen}
                type={ShareModalType.VIDEO}
                name={video.title}
                closeModal={() => setShareModalOpen(false)}
            />
        </div>
    )
}

const VideoPage = () => {

    const { user, darkMode } = useUserContext();
    const { id } = useParams();

    const [video, setVideo] = useState({});
    const [suggestedVideos, setSuggestedVideos] = useState([]);
    const [isLiked, setIsLiked] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            const results = await apiHelpers.retrieveVideoById(id, user)

            if (results.status !== STATUS_CODES.SUCCESS) {
                setError('There was an error retrieving this video. Please try again later.');
                setIsLoading(false)
            } else {
                setVideo(results.data.video)
                setSuggestedVideos(results.data.suggestedVideos)
                setIsLiked(results.data.video.isLiked)
                setIsLoading(false)
            }
        }

        fetchData();

        // Scroll back to the top of the page on page load
        window.scrollTo(0, 0)
    }, [id, user]);

    const Body = () => {
        if (error) {
            return <ErrorPage errorMessage={error} />
        } else if (isLoading) {
            return <LoadingPage />
        } else {
            return (
                <div className={`user-page video-page ${darkMode || ''}`} key={video.id || 0}>
                    <div className="video-page__body">
                        <MainVideo video={video} user={user} isLiked={isLiked} />
                        <SuggestedVideos videos={suggestedVideos} />
                    </div>
                </div>
            )
        }
    }



    return (
        <>
            <NavBar />
                <Body />
            <BottomBar />
        </>
    )
}

export default VideoPage;
