import '../css/VideoTags.css'

import { Link } from 'react-router-dom';
import React from 'react';

const VideoTags = ({ tags }) => {

    const Tag = ({ tag }) => {

        const { id, name } = tag || {};

        return (
            <Link className="tag" to={`/categories/${id}`}>
                {name}
            </Link>
        )
    }

    return (
        <div className="video-page__details-tags">
            <h3>Tags</h3>
            <div className="tags__items">
                {tags && tags.map((tag, index) => <Tag key={index} tag={tag} />)}
            </div>
        </div>
    )
}

export default VideoTags;
