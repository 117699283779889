import '../../css/SearchResultsPage.css';

import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import apiHelpers, { STATUS_CODES } from '../../helpers/apiHelpers';

import BottomBar from '../../components/BottomBar';
import { ErrorSection } from '../../components/Error';
import { LoadingPage } from '../../components/Loading';
import NavBar from '../../components/NavBar';
import VideoResults from '../../components/VideoResults';

const ArtistResult = ({ artist }) => {

    const { id, name, img_path } = artist || {};

    return (
        //TODO: Fix to be the artist id
        <Link to={`/artists/${id}`} className="artist-result">
            {img_path && <img src={img_path} alt={name} />}
            <p>{name}</p>
        </Link>
    )

}

const ArtistResults = ({ artists }) => {

    if (artists.length === 0) {
        return (
            <div className="artist-results">
                <p>No artists found</p>
            </div>
        )
    }

    return (
        <div className="artist-results">
            {artists &&
                    artists.map((artist, index) => <ArtistResult key={index} artist={artist} />)
            }
        </div>
    )
}

const SearchResultsPage = () => {

    const { query } = useParams();

    const [artistResults, setArtistResults] = useState([]);
    const [videoResults, setVideoResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            const results = await apiHelpers.searchVideosAndArtists(query);
            if (results.status !== STATUS_CODES.SUCCESS) {
                setError('There was an error loading search results. Please try again later.');
                setLoading(false)
            } else {
                setArtistResults(results.data.artists)
                setVideoResults(results.data.videos)
                setLoading(false)
            }

        }

        fetchData();
    }, [query])

    const getContent = () => {

        const numResults = artistResults.length + videoResults.length;

        if (loading) {
            return <LoadingPage />
        } else if (error) {
            return <ErrorSection errorMessage={error} />
        } else {
            return (
                <>
                    <p id="result-count">{numResults} {numResults === 1 ? 'result' : 'results'}</p>
                    <div className="search-results__artists">
                        <h2>Artists</h2>
                        <ArtistResults artists={artistResults} />
                    </div>
                    <div className="search-results__videos">
                        <h2>Videos</h2>
                        <VideoResults videos={videoResults} />
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <NavBar />
                <div className="user-page search-results-page">
                    <div className="content">
                        <h1 className="query">Search results for '{query}'</h1>
                        {getContent()}
                    </div>
                </div>
            <BottomBar />
        </>
    )

}

export default SearchResultsPage;
