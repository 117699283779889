import React, { useState } from 'react';
import apiHelpers, { STATUS_CODES } from '../helpers/apiHelpers';
import { validateEmail, validatePassword } from '../helpers/fieldValidators';

import Button from '../components/Button';
import ErrorMessage from './Error';
import InputField from './InputField';
import { UserAuthStateEnum } from './UserAuthModal';

const ForgotPasswordFormEnum = {
    ENTER_EMAIL: 'EnterEmail',
    ENTER_CODE: 'EnterRecoveryCode',
    ENTER_PASSWORD: 'EnterNewPassword',
    COMPLETE: 'Complete',
}

const formSubheadings = {
    [ForgotPasswordFormEnum.ENTER_EMAIL]: 'Enter your email address to receive a recovery code.',
    [ForgotPasswordFormEnum.ENTER_CODE]: 'Enter the recovery code sent to your email.',
    [ForgotPasswordFormEnum.ENTER_PASSWORD]: 'Enter a new password.',
    [ForgotPasswordFormEnum.COMPLETE]: 'Your password has been reset.',
}

const BackToLoginBtn = ({ updateFormState }) => {
    return (
        <Button
            text="Back to login"
            type="no-style"
            onClick={() => updateFormState(UserAuthStateEnum.LOGIN)}
        />
    )
}

const UserForgotPasswordForm = (props) => {

    const { updateFormState, handleClose, forgotPasswordFormState, testCode, testEmail } = props;

    // Set initial state of forgot password form if provided, otherwise set to EnterEmail
    const [forgotPasswordState, setForgotPasswordState] = useState(forgotPasswordFormState || ForgotPasswordFormEnum.ENTER_EMAIL)

    const [isLoading, setIsLoading] = useState(false)

    const [formData, setFormData] = useState({
        email: testEmail || '',
        recoveryCode: testCode || '',
        password: '',
        confirmPassword: '',
    })

    const [messages, setMessages] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        code: '',
        api: '',
    })

    const validateFields = (email, password, confirmPassword, code) => {

        const fieldErrors = {
            email: '',
            password: '',
            confirmPassword: '',
            code: '',
        }

        switch(forgotPasswordState) {
            case ForgotPasswordFormEnum.ENTER_EMAIL:
                if (!email) {
                    fieldErrors.email = 'Email is required'
                } else if (!validateEmail(email) && email !== 'user' && email !== 'admin') {
                    // user and admin are for testing purposes, remove when backend is ready
                    fieldErrors.email = 'Please enter a valid email'
                }
                break;
            case ForgotPasswordFormEnum.ENTER_CODE:
                if (!email) {
                    fieldErrors.email = 'Email is required'
                } else if (!validateEmail(email) && email !== 'user' && email !== 'admin') {
                    // user and admin are for testing purposes, remove when backend is ready
                    fieldErrors.email = 'Please enter a valid email'
                }
                if (!code) {
                    fieldErrors.code = 'Recovery code is required'
                }
                break;
            case ForgotPasswordFormEnum.ENTER_PASSWORD:
                if (!password) {
                    fieldErrors.password = 'Password is required'
                } else if (!validatePassword(password)) {
                    fieldErrors.password = 'Password does not meet requirements: 1 uppercase, 1 lowercase, 1 number, 1 special character, 8 characters'
                }
                if (!confirmPassword) {
                    fieldErrors.confirmPassword = 'Confirm password is required'
                } else if (confirmPassword !== password) {
                    fieldErrors.confirmPassword = 'Passwords do not match'
                }
                break;
            default:
                break;
        }

        return fieldErrors;
    }

    const onEmailSubmit = async (e) => {

        e.preventDefault()

        const email = document.getElementById('email').value

        const fieldErrors = validateFields(email)
        const valid = Object.values(fieldErrors).every(field => field === '')

        if (!valid) {
            setMessages({...fieldErrors, api: ''})
            return
        }

        setIsLoading(true)
        const result = await apiHelpers.sendRecoveryCode(email)

        if (result.status !== STATUS_CODES.SUCCESS && result.status !== STATUS_CODES.POST_SUCCESS) {
            setMessages({ fieldErrors, email: '', api: result.message })
            setIsLoading(false)
        } else {
            setMessages({ ...messages, email: '',  api: '' })
            setFormData({ ...formData, email: email })
            setForgotPasswordState(ForgotPasswordFormEnum.ENTER_CODE)
            setIsLoading(false)
        }

    }

    const onCodeSubmit = async (e) => {

        e.preventDefault()

        const email = document.getElementById('email').value
        const code = document.getElementById('code').value

        const fieldErrors = validateFields(email, null, null, code)
        const valid = Object.values(fieldErrors).every(field => field === '')

        if (!valid) {
            setMessages({...fieldErrors, api: ''})
            return
        }

        setIsLoading(true)

        const result = await apiHelpers.validateRecoveryCode(email, code)

        if (result.status !== STATUS_CODES.SUCCESS && result.status !== STATUS_CODES.POST_SUCCESS) {
            setMessages({ ...messages, api: result.message })
            setIsLoading(false)
        } else {
            setMessages({ ...messages, api: '' })
            setFormData({ ...formData, email: email, recoveryCode: code })
            setForgotPasswordState(ForgotPasswordFormEnum.ENTER_PASSWORD)
            setIsLoading(false)
        }

    }

    const onPasswordSubmit = async (e) => {

        e.preventDefault()

        const password = document.getElementById('password').value
        const confirmPassword = document.getElementById('confirm-password').value

        const fieldErrors = validateFields(null, password, confirmPassword, null)
        const valid = Object.values(fieldErrors).every(field => field === '')

        if (!valid) {
            setMessages({...fieldErrors, api: ''})
            return
        }

        setIsLoading(true)
        const result = await apiHelpers.updateUserPassword(formData.email, formData.recoveryCode, password)

        if (result.status !== STATUS_CODES.SUCCESS && result.status !== STATUS_CODES.POST_SUCCESS) {
            setMessages({ ...messages, api: result.message })
            setIsLoading(false)
        } else {
            setMessages({ ...messages, api: '' })
            setForgotPasswordState(ForgotPasswordFormEnum.COMPLETE)
            setIsLoading(false)
        }
    }

    const Body = () => {

        switch(forgotPasswordState) {
            case ForgotPasswordFormEnum.COMPLETE:
                return (
                    <Button
                        onClick={() => updateFormState(UserAuthStateEnum.LOGIN)}
                        type="primary"
                        disabled={isLoading}
                        text="SIGN IN"
                    />
                )

            case ForgotPasswordFormEnum.ENTER_CODE:
                return (
                    <>
                        <InputField
                            id="email"
                            type="email"
                            placeholder="Enter email address"
                            label="Email"
                            error={messages.email}
                            initialValue={formData.email || ''}
                            compact
                        />
                        <InputField
                            id="code"
                            type="text"
                            placeholder="Enter recovery code"
                            label="Recovery Code"
                            error={messages.code}
                            compact
                        />
                        <div className="form-controls">
                            <Button
                                text="SUBMIT"
                                onClick={onCodeSubmit}
                                type="primary"
                                disabled={isLoading}
                                isSubmit
                            />
                        </div>

                    </>
                )
            case ForgotPasswordFormEnum.ENTER_PASSWORD:
                return (
                    <>
                        <InputField
                            id="password"
                            type="password"
                            placeholder="Enter new password"
                            label="New Password"
                            error={messages.password}
                            compact
                        />
                        <InputField
                            id="confirm-password"
                            type="password"
                            placeholder="Re-enter new password"
                            label="Confirm New Password"
                            error={messages.confirmPassword}
                            compact
                        />
                        <div className="form-controls">
                            <Button
                                text="CHANGE PASSWORD"
                                onClick={onPasswordSubmit}
                                type="primary"
                                disabled={isLoading}
                                isSubmit
                            />
                        </div>
                    </>
                )
            default:
            case ForgotPasswordFormEnum.ENTER_EMAIL:
                return (
                    <>
                        <InputField
                            id="email"
                            type="email"
                            placeholder="Enter email address"
                            label="Email"
                            error={messages.email}
                            compact
                        />
                        <div className="form-controls">
                            <Button
                                text="Already have a recovery code?"
                                onClick={() => setForgotPasswordState(ForgotPasswordFormEnum.ENTER_CODE)}
                                type="no-style"
                                disabled={isLoading}
                            />
                            <Button
                                text="SEND CODE"
                                onClick={onEmailSubmit}
                                disabled={isLoading}
                                type="primary"
                                isSubmit
                            />
                        </div>

                    </>)
        }
    }


    return (
        <form id="forgot-password-form">
            <div className="form-header-controls">
                {forgotPasswordState === ForgotPasswordFormEnum.COMPLETE ? null : <BackToLoginBtn updateFormState={updateFormState}/>}
                <Button
                    onClick={handleClose}
                    type="no-style"
                    text="Close"
                />
            </div>
            <div className="forgot-pass-form__header">
                <h1>Forgot Password?</h1>
                <p>{formSubheadings[forgotPasswordState]}</p>
            </div>
            <ErrorMessage errorMessage={messages.api} />
            <Body />
        </form>
    )

}

export {
    UserForgotPasswordForm,
    ForgotPasswordFormEnum
};

