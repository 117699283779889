import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

import axios from 'axios';

const UserContext = createContext();

const UserContextProvider = ({ children }) => {

    // Initialize user state based on whether or not a user is logged in
    const [user, setUser] = useState(
        localStorage.getItem("user") ?
        JSON.parse(localStorage.getItem("user")) :
        null
    )

    // Clears all logged in user data
    const logout = useCallback(() => {
        localStorage.setItem("user", null);
        setUser(null);
    }, [])

    // Calls the login API and sets the user state, both in the localStorage and in the context
    const login = useCallback( async (email, password) => {

        const result = await axios.post("/api/login", { email, password })
        .then(res => {
            const data = {
                ...res.data
            }

            // Get auth token from header
            const token = res.headers["authorization"].split(" ")[1];

            data.token = token;

            return data
        })
        .catch(err => err.response.data)

        if (result.status === 201) {

            const updatedUser = {
                id: result.data.userid,
                firstName: result.data.firstName,
                isAdmin: result.data.isAdmin,
                token: result.token
            }
            // console.log(updatedUser);
            setUser(updatedUser)
            localStorage.setItem("user", JSON.stringify(updatedUser));
        }

        return result;

    }, [])

    // Calls the register API and sets the user state, both in the localStorage and in the context
    const register = useCallback( async (email, password, first_name, last_name, birthday, zipcode, checked) => {

        const results = await axios.post("/api/register", {
            firstName: first_name,
            lastName: last_name,
            zipCode: zipcode,
            birthday: birthday,
            email: email,
            password: password,
            newsletter: checked ? "1" : "0",
        }).then((res) => {
            const data = {
                ...res.data
            }

            // Get auth token from header
            const token = res.headers["authorization"].split(" ")[1];

            data.token = token;

            return data
        }).catch((err) => {
            return {
                status: err.response.data.status,
                message: err.response.data.message,
            }
        })

        if (results.status === 201) {
            const updatedUser = {
                id: results.data.userid,
                firstName: results.data.firstName,
                isAdmin: results.data.isAdmin,
                token: results.token
            }

            setUser(updatedUser)
            localStorage.setItem("user", JSON.stringify(updatedUser));
        }

        return results
    }, [])

    const contextValue = useMemo(() => ({
        user,
        logout,
        login,
        register,
    }), [user, logout, login, register]);


    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
}

const useUserContext = () => {

    const context = useContext(UserContext);

    if (!context) {
        throw new Error("useUserContext must be used within a UserContextProvider");
    }

    return context;
}

export { UserContext, UserContextProvider, useUserContext };
