import React, { useState } from 'react';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminForms.css'
import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminConfirmDelete.css'


const AdminConfirmDelete = ({ trigger, showConfirmation, userInfo, doDelete, authorization }) => {
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [colorMessage, setColorMessage] = useState('red');

    const deleteUser = (user) => {
        async function removeUser(user) {
            const query = "" + user.id;
            const result = await adminApiHelpers.deleteUser(query, authorization);
            if (result.status !== STATUS_CODES.SUCCESS) {
                setMessage("Error deleting user");
                setColorMessage('red');
                temporaryShow();
            } else {
                setMessage("User Deleted Successfully");
                setColorMessage('green');
                temporaryShow();
                setTimeout(() => {
                    showConfirmation();
                    doDelete(user);
                }, 3000);
            }
        }
        
        removeUser(user);
    }

    const temporaryShow = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);
    }

    const showDeleteResult = () => {
        return showMessage ? (
            <div className={`message-box ${colorMessage}`}>
                <p>{message}</p>
            </div>
        ) : "";
    }
    
    return (trigger) ? (
        <>
            <div className='popup-background'>
                <div className='popup-container-for-delete'>
                    <div className='remove-popup-container'>
                        <button className='remove-popup-button' onClick={showConfirmation}>&#10006;</button>
                    </div>
                    <h1>Confirm User Deletion</h1>
                    <div className='user-info-container'>
                        <p className='user-info'>User: {userInfo.name}</p>
                        <p className='user-info'>Email: {userInfo.email}</p>
                        <p className='user-info'>Last Login: {userInfo.last_login}</p>
                    </div>
                    <div className='confirm-delete-message'>
                        <p>Are you sure you want to delete this user?</p>
                        <p>This process cannot be undone</p>
                    </div>
                    <div className='button-section'>
                        <button onClick={() => deleteUser(userInfo)} className='quick-action-button increase-size'>Delete User</button>
                    </div>
                    {showDeleteResult()}
                </div>
            </div>
        </>
    ) : "";
}

export default AdminConfirmDelete;