import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../context/UserContext';
import { Navigate, Link } from 'react-router-dom'
import adminApiHelpers from '../../helpers/adminApiHelpers';
import { STATUS_CODES } from '../../helpers/apiHelpers';

import AdminNavbar from '../../components/admin-components/AdminNavbar';
import AdminHeadbar from '../../components/admin-components/AdminHeadbar';
import AdminNavbarButton from '../../components/admin-components/AdminNavbarButton';
import AdminHomeVideoList from '../../components/admin-components/AdminHomeVideoList';
import AdminHomeArtistList from '../../components/admin-components/AdminHomeArtistList';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminErrorMessages.css'

const AdminDashboard = () => {
    const [sidebar, setSidebar] = useState(true);
    const [videoList, setVideoList] = useState([]);
    const [artistList, setArtistList] = useState([]);
    const [displayError, setDisplayError] = useState({
        display: false,
        color: 'red',
        message: ""
    });

    // Get information about the currently logged in user, if any
    const { user, logout } = useUserContext();

    useEffect(() => {
        async function fetchData() {
            const dashboardData = await adminApiHelpers.retrieveDashboardData(user);

            if (dashboardData.status !== STATUS_CODES.SUCCESS) {
                console.log("Error Loading Page");
                
            } else {
                setVideoList(dashboardData.data.data.episodes);
                setArtistList(dashboardData.data.data.artists);
            }
        }

        fetchData();
    }, [user])

    const importVideos = () => {
        async function importRecentVideos() {
            const result = await adminApiHelpers.importRecentVideos(user);
            if (result.status !== STATUS_CODES.SUCCESS) {
                setDisplayError({
                    display: true,
                    color: 'red',
                    message: "Error Importing Recent Videos"
                })
            } else {
                setDisplayError({
                    display: true,
                    color: 'green',
                    message: "Videos Successfully Imported"
                })
            }
        }

        importRecentVideos();
    }

    const updateTags = () => {
        async function updateAllTags() {
            const result = await adminApiHelpers.updateAllTags(user);
            if (result.status !== STATUS_CODES.SUCCESS && result.status !== STATUS_CODES.POST_SUCCESS) {
                setDisplayError({
                    display: true,
                    color: 'red',
                    message: "Error Updating Tags"
                })
            } else {
                setDisplayError({
                    display: true,
                    color: 'green',
                    message: "Tags Successfully Updated"
                })
            }
        }

        updateAllTags();
    }

    const showcaseError = () => {
        return (displayError.display) ?  <div className={`dashboard-err ${displayError.color}`}>
        <p>{displayError.message}</p>
        <p  style={{ cursor: "pointer" }}
            onClick={() => {
                setDisplayError({
                    display: false,
                    color: 'red',
                    message: ''
                })
            }}
        >&#10006;</p> 
    </div> : <></>;
    }

    // If user isn't logged in or is not an admin, redirect to admin home page
    if (!user || !user.isAdmin) {
        return <Navigate to="/admin" />
    }

    const showSidebar = () => { setSidebar(!sidebar); }

    return (
        <>
            <AdminHeadbar />
            <AdminNavbar sidebarStatus={sidebar} adminName={user.firstName} currentPage="Dashboard" logout={logout}/>
            <div className={sidebar ? 'main-site active' : 'main-site'}>
                <AdminNavbarButton sidebarStatus={sidebar} showSidebar={showSidebar}/>
                <h1>Dashboard</h1>
                <p>Welcome, {user.firstName}</p>
                <div className='summary-columns'>
                    <div className='one-column'>
                        <div className='action-title'>
                            <p className='boxed-text dashboard'>Popular Videos</p>      
                        </div>
                        <AdminHomeVideoList videoList={videoList}/>
                    </div>

                    <div className='one-column'>
                        <div className='action-title'>
                            <p className='boxed-text dashboard'>Popular Artists</p>
                        </div>
                        <AdminHomeArtistList artistList={artistList}/>
                    </div>

                    <div className='one-column'>
                        <div className='action-title'>
                            <p className='boxed-text dashboard'>Quick Actions</p>
                        </div>
                        <div className='quick-action-menu'>
                            <button className='dashboard-button' onClick={() => importVideos()}>Import Videos</button>
                            <button className='dashboard-button' onClick={() => updateTags()}>Update Tags</button>
                            <Link to='/admin/newVideo' style={{ textDecoration: 'none' }}>
                                <h3 className='dashboard-button'>Add Video</h3>
                            </Link>
                            <Link to='/admin/newArtist' style={{ textDecoration: 'none' }}>
                                <h3 className='dashboard-button'>Add Artist</h3>
                            </Link>
                            <Link to='/admin/newAdmin' style={{ textDecoration: 'none' }}>
                                <h3 className='dashboard-button'>Add Administrator</h3>
                            </Link>
                        </div>
                        {showcaseError()}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDashboard;
