import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { STATUS_CODES } from '../../helpers/apiHelpers';
import adminApiHelpers from '../../helpers/adminApiHelpers';

import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminForms.css'

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { display, style } from '@mui/system';

//TODO: Save image somehow
const AdminArtistForm = ({ pageTitle, artistToEdit, newArtist, artistId, user }) => {
    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);
    const defaultPicture = "https://nashvilleinsider.tv/wp-content/uploads/2018/04/logo-yes.png";
    const [errorMessage, setErrorMessage] = useState(null)
    const [confirmCompletion, setConfirmCompletion] = useState(false)
    const [artistInfo, setArtistInfo] = useState(artistToEdit)
    const [imageQuery, setImageQuery] = useState(artistToEdit.image)
    const [noPicture, setNoPicture] = useState(false);
    const [showPicture, setShowPictrue] = useState(newArtist)

    const handleQuery = (e) => {
        e.preventDefault()

        setImageQuery(e.target.value);
    }

    // const handleImageUpload = (e) => {
    //     const [file] = e.target.files;
    //     if (file) {
    //         const reader = new FileReader();
    //         const {current} = uploadedImage;
    //         current.file = file;
    //         reader.onload = (e) => {
    //             current.src = e.target.result;
    //         }
    //         reader.readAsDataURL(file)
    //         console.log(uploadedImage.current);
    //         if (!showPicture) setShowPictrue(true);
    //     }
    // }

    // const changePublic = (publicStatus) => {
    //     setArtistInfo(prevState => ({
    //         ...prevState,
    //         public: publicStatus
    //     }));
    // }

    const changeFeature = (featureStatus) => {
        setArtistInfo(prevState => ({
            ...prevState,
            featured: featureStatus
        }));
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        switch (name) {
            case 'name':
                setArtistInfo(prevState => ({
                    ...prevState,
                    name: value
                }))
                break;
            case 'description':
                setArtistInfo(prevState => ({
                    ...prevState,
                    description: value
                }))
                break;
            default: 
                break;
        }
    }

    const handleSubmit = () => {
        let pictureLink = checkPicture(imageQuery);

        if (artistInfo.name === '' || artistInfo.description === '') {
            setErrorMessage("Please fill all required fields")
            return;
        }

        async function postArtist(picture) {
            if (newArtist) {

                let artistToSubmit = {
                    name: artistInfo.name,
                    description: artistInfo.description,
                    img_path: picture,
                    featured: artistInfo.featured
                }

                const addArtist = await adminApiHelpers.createNewArtist(artistToSubmit, user);
                if (addArtist.status !== STATUS_CODES.POST_SUCCESS) {
                    setErrorMessage("Error creating new artist");
                } else {
                    setArtistInfo(artistToEdit);
                    setImageQuery('');
                    setConfirmCompletion(true);
                    setErrorMessage('Artist Successfully created')
                }
            } else {
                let artistToSubmit = {
                    id: artistId,
                    name: artistInfo.name,
                    description: artistInfo.description,
                    img_path: picture,
                    featured: artistInfo.featured
                }

                const editArtist = await adminApiHelpers.editArtist(artistToSubmit, user);
                
                if (editArtist.status !== STATUS_CODES.SUCCESS) {
                    setErrorMessage("Error editing artist");
                } else {
                    setArtistInfo(artistToEdit);
                    setImageQuery('');
                    setConfirmCompletion(true);
                    setErrorMessage('Artist Successfully edited')
                }
            }
        }

        postArtist(pictureLink);
    }

    const showErrorMessage = () => {
        let green = (confirmCompletion ? 'green' : 'red')
        return (
            <div className={(errorMessage === null) ? 'admin-form-err' : `admin-form-err active ${green}`}>
                <p>{errorMessage}</p>
                <p 
                    onClick={() => {
                        setErrorMessage(null);
                        setConfirmCompletion(false);
                    }}
                    
                    className={(errorMessage === null) ? 'remove-error' : 'remove-error active'}
                >&#10006;</p> 
            </div>
        );
    }

    const checkPicture = (path) => {
        const img = new Image();
        img.src = path;
        if (img.complete) {
            return imageQuery;
        } else {
            return defaultPicture;
        }
    }

    return (
        <>
            <h1>{pageTitle}</h1>
            <div className='artist-form-container'>
                <div className='artist-form-upper-half'>
                    <div className='artist-form-picture-container'>
                        <input 
                            className='search-input' type='text' value={imageQuery}
                            placeholder='Find an artist' onChange={handleQuery}>
                        </input>
                        <div className='artist-image-container'>
                            <img src={imageQuery} className='featured-artist-image' alt={artistInfo.name}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="https://nashvilleinsider.tv/wp-content/uploads/2018/04/logo-yes.png";
                                    setShowPictrue(true);
                                }}
                            />
                        </div>
                        {/* <p 
                            onClick={() => imageUploader.current.click()}
                            className='quick-action center'
                        > 
                            <FileUploadIcon/> Add Picture
                        </p>
                        <input 
                            onChange={handleImageUpload} ref={imageUploader}
                            type='file' multiple={false}
                            style={{display: "none"}}
                        />
                        <div className='artist-image-container'>
                            <p className='message-in-image-container'>Select  a file to upload</p>
                            {showPicture ? <img 
                                ref={uploadedImage}
                                className='artist-image'
                            /> : <img 
                            // src={artistInfo.picture}
                            ref={uploadedImage}
                            className='artist-image'
                        />}
                        </div> */}
                    </div>
                    <div className='artist-form-info'>
                        <div className='artist-input-container'>
                            <p className='bold'>Artist Name</p>
                            <input 
                                type='text' placeholder="Enter artist's name"
                                className='artist-name-input' value={artistInfo.name}
                                onChange={handleChange} name='name'
                            />
                        </div>
                        <div className='artist-input-container'>
                            <p className='bold'>Description</p>
                            <textarea 
                                type='text' placeholder="Enter artist's description"
                                className='artist-description-input' value={artistInfo.description}
                                onChange={handleChange} name='description'
                            />
                        </div>
                    </div>
                </div>
                <div className='artist-form-lower-half'>
                    <div className='privacy-settings-container'>
                        <p className='boxed-text bold'>Feature Settings</p>
                        <div>
                            <label className='privacy-setting-container'>
                                <input 
                                    type='radio' name='privacy-setting' 
                                    onChange={() => changeFeature(1)}
                                    checked={artistInfo.featured}
                                />
                                <div>
                                    <p className='bold'>Featured</p>
                                    <p className='smaller-font'>This artist will be easy to find in the user's artist page</p>
                                </div>
                            </label>
                            <label className='privacy-setting-container'>
                                <input 
                                    type='radio' name='privacy-setting' 
                                    onChange={() => changeFeature(0)}
                                    checked={!artistInfo.featured}
                                />
                                <div>
                                    <p className='bold'>Not Featured</p>
                                    <p className='smaller-font'>This artist will still be found through regular searches</p>
                                </div>
                            </label>
                        </div>
                        {/* <p className='boxed-text bold'>Privacy Settings</p>
                        <div>
                            <label className='privacy-setting-container'>
                                <input type='radio' name='privacy-setting' onChange={() => changePublic(true)}/>
                                <div>
                                    <p className='bold'>Public</p>
                                    <p className='smaller-font'>Anyone on Nashville Insider can watch this video</p>
                                </div>
                            </label>
                            <label className='privacy-setting-container'>
                                <input type='radio' name='privacy-setting' onChange={() => changePublic(false)}/>
                                <div>
                                    <p className='bold'>Private</p>
                                    <p className='smaller-font'>Only you and other admins can see this video</p>
                                </div>
                            </label>
                        </div> */}
                    </div>
                    <div className='artist-form-button-section'>
                        {/* <button className='quick-action-button'>Preview</button> */}
                        <Link to='/admin/newArtist' onClick={() => window.location.reload()}>
                            <button className='quick-action-button form'>Discard</button>
                        </Link>
                        <button onClick={() => handleSubmit()} className='quick-action-button form'>Publish</button>
                    </div>
                </div>
                {showErrorMessage()}
            </div>
        </>
    );
}

export default AdminArtistForm;