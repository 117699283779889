export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

// Password contains at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character
export const validatePassword = (password) => {
    return String(password).match(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
    );
};

export const validateBirthday = (birthday) => {
    const now = new Date()
    const latestDate = new Date(now.getFullYear() - 13, now.getMonth(), now.getDate()).toISOString().split('T')[0]

    return birthday <= latestDate
}

const fieldValidators = {
    validateBirthday,
    validateEmail,
    validatePassword,
};

export default fieldValidators;
