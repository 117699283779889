import '../../css/SingleArtistPage.css';

import Button, { LikeButton, ShareButton } from '../../components/Button';
import { ErrorPage, ErrorSection } from '../../components/Error';
import React, { useEffect, useState } from 'react';
import ShareModal, { ShareModalType } from '../../components/ShareModal';
import apiHelpers, { STATUS_CODES } from '../../helpers/apiHelpers';
import { useNavigate, useParams } from 'react-router-dom';

import BottomBar from '../../components/BottomBar';
import { LoadingPage } from '../../components/Loading';
import NavBar from '../../components/NavBar';
import VideoResults from '../../components/VideoResults';
import { useUserContext } from '../../context/UserContext';

const RelatedVideos = ({ videos, tagId }) => {

    const navigate = useNavigate();

    const handleViewAll = () => {
        navigate(`/categories/${tagId}`);
    }

    return (
        <div className="related-videos">
            <div className="related-videos__header">
                <h3>Related Videos</h3>
                {videos.length > 0 && tagId && <Button text="View All" size="small" onClick={handleViewAll}/>}
            </div>
            <VideoResults videos={videos}/>
        </div>
    )
}

const ArtistImage = ({ name, image }) => {

    return (
        <div className="single-artist-page__artist-image">
            <img src={image} alt={name} id="artist-image"/>
        </div>
    )
}

const ArtistInfo = ({ artist, user }) => {

    const { name, description, isLiked, id } = artist;

    const [liked, setLiked] = useState(isLiked);
    const [shareOpen, setShareOpen] = useState(false);

    const handleLikeButtonClick = async () => {
        const results = await apiHelpers.updateLikedArtist(user, id, !liked);
        if (results.status !== STATUS_CODES.SUCCESS) {
            console.log(`There was an error ${isLiked ? 'unliking' : 'liking'} this artist`);
        } else {
            setLiked(!liked);
        }
    }

    const handleShareButtonClick = async () => {
        setShareOpen(!shareOpen);
    }

    return (
        <div className="single-artist-page__artist-info">
            <div className="artist-info__top-row-details">
                <div className="top-row-details__artist-name">{name}</div>
                <div className="top-row-details__interaction">
                    <LikeButton onClick={handleLikeButtonClick} isLiked={liked}/>
                    <ShareButton className='share-btn' onClick={handleShareButtonClick}/>
                    <ShareModal
                        isOpen={shareOpen}
                        type={ShareModalType.ARTIST}
                        closeModal={() => setShareOpen(false)}
                        name={name}
                    />
                </div>
            </div>
            <div className="single-artist-page__artist-biography">{description}</div>
        </div>
    )
}

const SingleArtistPage = () => {
    const { id } = useParams();
    const { user } = useUserContext();
    const [artist, setArtist] = useState(null);
    const [videos, setVideos] = useState(null);
    const [tagId, setTagId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const results = await apiHelpers.retrieveArtistById(id, user);

            if (results.status !== STATUS_CODES.SUCCESS) {
                setError('There was an error loading this artist. Please try again later.')
                setIsLoading(false);
            } else {
                setArtist(results.data.artist);
                setVideos(results.data.videos);
                setTagId(results.data.tagid);
                setIsLoading(false);
            }
        }

        fetchData();

        // Scroll back to the top of the page on page load
        window.scrollTo(0, 0)
    }, [id, user]);

    const Body = () => {


        if (user && error) {
            return <ErrorPage errorMessage={error} />
        } else if (isLoading) {
            return <LoadingPage />
        } else if (user) {
            return (
                <div className="user-page single-artist-page">
                    <div className="single-artist-page__body">
                        {artist && <div className="single-artist-page__artist">
                            <ArtistImage image={artist.image} name={artist.name} />
                            <ArtistInfo artist={{ id: id, ...artist}} user={user}/>
                        </div>
                        }
                        {videos && <RelatedVideos videos={videos} tagId={tagId}/>}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="single-artist-page__artist">
                    <ErrorSection errorMessage="You must be logged in to view this page" />
                </div>
            )
        }
    }

    const signUpSubheading = "Sign up to learn more about your favorite artists!";

    return (
        <>
            <NavBar loginRequired={!user} subheading={signUpSubheading}/>
                <Body />
            <BottomBar />
        </>
    )
};

export default SingleArtistPage;
