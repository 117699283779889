import React, { useState } from 'react';
import apiHelpers, { STATUS_CODES } from '../../helpers/apiHelpers';
import { validatePassword } from '../../helpers/fieldValidators';

import '../../css/admin-css/AdminForms.css'
import '../../css/admin-css/AdminSite.css'
import '../../css/admin-css/AdminLogin.css'

const AdminChangeForgotPssw = ({ trigger, finishRecovery, email, code }) => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null)
    const [color, setColor] = useState("red");

    const tempShowError = (message) => {
        setTimeout(() => {
            setErrorMessage(null);
        }, 3000);
        setErrorMessage(message);
    }

    const showErrorMessage = () => {
        return (
            <div className={(errorMessage === null) ? 'form-error' : (`form-error active center ${color}`)}>
                <p>{errorMessage}</p>
                <p 
                    onClick={() => setErrorMessage(null)} 
                    className={(errorMessage === null) ? 'remove-error' : 'remove-error active'}
                >&#10006;</p> 
            </div>
        );
    }

    // Display success message and go back to login page
    const completePasswordChange = () => {
        setTimeout(() => {
            setErrorMessage(null);
            setColor("red");
            finishRecovery();
        }, 3000);
        setColor("green");
        setErrorMessage("Password Changed Successfully");
    }

    // Update admin password
    const changePassword = () => {
        if (newPassword === '' || confirmPassword === '') {
            tempShowError("Please fill out all fields");
            return;
        } else if (!validatePassword(newPassword)) {
            tempShowError("New password not in correct format");
            return;
        } else if (newPassword !== confirmPassword) {
            tempShowError("Passwords do not match");
            return;
        }
        

        async function updateAdminPssw() {
            let newCode = code.toString();
            const result = await apiHelpers.updateUserPassword(email, newCode, newPassword)

            if (result.status !== STATUS_CODES.SUCCESS && result.status !== STATUS_CODES.POST_SUCCESS) {
                tempShowError("Error updating your password, please try again later");
                return;
            } else {
                completePasswordChange();
            }
        }

        updateAdminPssw();
    }

    // Update form fields
    const inputChange = (e) => {
        e.preventDefault()

        const {name, value } = e.target 

        switch(name) {
            case 'newPassword':
                setNewPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
        }
    }

    return (trigger) ? <>
        <div className='recovery-background'>
            <div className='change-pssw-container'>
                <div className='remove-popup-container'>
                    <button className='remove-popup-button' onClick={finishRecovery}>&#10006;</button>
                </div>
                <div className='forgot-pssw-popup'>
                    <h1>Change Password</h1>
                    <p>Enter your new password</p>
                </div>
                <div className='pssw-container'>
                    <p className='email-text'>New Password</p>
                    <div className='login-data'>
                        <input 
                            type='password' placeholder='Enter new password'
                            name='newPassword' value={newPassword} onChange={inputChange}
                        />
                    </div>
                </div>
                <div className='email-container'>
                    <p className='email-text'>Confirm Password</p>
                    <div className='login-data'>
                        <input 
                            type='password' placeholder='Confirm new password'
                            name='confirmPassword' value={confirmPassword} onChange={inputChange}
                        />
                    </div>
                </div>
                <div className='recovery-section'>
                    <button onClick={() => changePassword()}>SUBMIT</button>
                </div>
                {showErrorMessage()}
            </div>
        </div>
    </> : <></>
}

export default AdminChangeForgotPssw;