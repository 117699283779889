import '../../css/AboutPage.css';

import BottomBar from '../../components/BottomBar';
import NavBar from '../../components/NavBar';
import React from 'react';

const AboutPage = () => (
    <>
        <NavBar />
        <div className="about-page user-page">
            <div className="about-page__body">
                <h1>About Us</h1>
                <p>This website and its accompanying mobile application was created
                    as an extension of the original Nashville Insider website as a
                    place for Nashville Insider visitors to view all created video
                    content and learn more about their favorite country music artists.
                    This project was initially worked on by a team of computer science
                    students at the University of Central Florida in Orlando, Florida.
                    The names of these students are Ariane Avendano, Rudolph Garcia,
                    Carlos Luengo, Sarah Myerson, and Deundre Williams.</p>
            </div>
        </div>
        <BottomBar />
    </>
)

export default AboutPage;
